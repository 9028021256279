// import React, { useContext, useEffect, useRef, useState } from 'react'
// import * as S from './styles'
// import { DocsContext } from '../../context/docs-context'
// import { loadingDocSummary } from '../../constant/documentstype'
// // import { getAppInsights } from '../../config/app-insights-config'

// const FormComponents = ({ onUpdateFormData }) => {
//   // const appInsights = getAppInsights()
//   const {
//     dataDocumentToLint,
//     isLoadingForm,
//     updatedDataDocumentToLint,
//     activeTag,
//     resumeTags,
//     formFields,
//     handleCheckFormEmpty,
//   } = useContext(DocsContext)
//   const [formData, setFormData] = useState({ data: {} })
//   const [labelToForm, setLabelToForm] = useState([])
//   const formRef = useRef(null)

//   useEffect(() => {
//     console.log('resumeTags', resumeTags)
//     if(activeTag) {
//       const hasActiveTagToForm = resumeTags.find(
//         (item) => item.tag === activeTag.part
//       )
//       console.log('hasActiveTag', hasActiveTagToForm)
//       console.log('formFields', formFields)
//       setLabelToForm(hasActiveTagToForm?.itens)
//     }
//   }, [activeTag])

//   useEffect(() => {
//     console.log('labelToForm', labelToForm)
//   }, [activeTag])

//   useEffect(() => {
//     const hasDataIa = dataDocumentToLint.find(
//       (item) =>
//         item.part === activeTag.part && item.numberTag === activeTag.numberTag
//     )

//     console.log("hasDataIa:", hasDataIa)

//     if (hasDataIa) {
//       if (JSON.stringify(formData.data) !== JSON.stringify(hasDataIa.data)) {
//         setFormData({ data: hasDataIa.data })
//         onUpdateFormData({ data: hasDataIa.data })

//         // appInsights &&
//         //   appInsights.trackEvent({
//         //     name: 'FormDataUpdateWithAI',
//         //     properties: {
//         //       part: activeTag.part,
//         //       numberTag: activeTag.numberTag,
//         //       updatedFields: Object.keys(hasDataIa.data),
//         //     },
//         //   })
//       }

//       // appInsights &&
//       //   appInsights.trackEvent({
//       //     name: 'FormDataCleared',
//       //     properties: {
//       //       part: activeTag.part,
//       //       numberTag: activeTag.numberTag,
//       //     },
//       //   })
//     }

//     // else if (Object.keys(formData.data).length > 0) {
//     //   console.log(' é aqui :?')
//     //   setFormData({ data: {} });
//     //   onUpdateFormData({ data: {} });
//     // }
//   }, [dataDocumentToLint, activeTag])

//   const handleInputChange = (e) => {
//     const { name, value } = e.target

//     setFormData((prevFormData) => {
//       const updatedFormData = {
//         ...prevFormData,
//         data: {
//           ...prevFormData.data,
//           [name]: value,
//         },
//       }
//       onUpdateFormData(updatedFormData)

//       // appInsights &&
//       //   appInsights.trackEvent({
//       //     name: 'FormFieldChange',
//       //     properties: {
//       //       fieldName: name,
//       //       fieldValue: value,
//       //       part: activeTag.part,
//       //       numberTag: activeTag.numberTag,
//       //     },
//       //   })

//       return updatedFormData
//     })
//     // console.log({name, value})
//     updatedDataDocumentToLint(name, value)
//   }

//   useEffect(() => {
//     const checkFormEmpty = Object.values(formData.data).every(
//       (value) => value === ''
//     )
//     handleCheckFormEmpty(checkFormEmpty)
//     // console.log("Formulário vazio:", checkFormEmpty);

//     // appInsights &&
//     //   appInsights.trackEvent({
//     //     name: 'FormEmptyCheck',
//     //     properties: {
//     //       isEmpty: checkFormEmpty,
//     //       part: activeTag.part,
//     //       numberTag: activeTag.numberTag,
//     //     },
//     //   })
//   }, [formData])

//   useEffect(() => {
//     console.log('formData:', formData)
//   })

//   return isLoadingForm ? (
//     <S.LoadingList>
//       {loadingDocSummary.map((item, idx) => (
//         <S.LoadingListItem key={idx}>
//           {item}
//           <label></label>
//           <p></p>
//         </S.LoadingListItem>
//       ))}
//     </S.LoadingList>
//   ) : (
//     <S.Form ref={formRef}>
//       {labelToForm && labelToForm?.map((doc, index) => (
//         <S.WrapperInput key={index}>
//           <S.Label>{doc.label}</S.Label>
//           <S.Input
//             type="text"
//             name={doc.value}
//             disabled={activeTag.dataValidated}
//             value={formData.data[doc.key] || ''}
//             onChange={handleInputChange}
//             style={{
//               cursor: activeTag.dataValidated ? 'not-allowed' : 'auto',
//             }}
//           />
//         </S.WrapperInput>
//       ))}
//     </S.Form>
//   )
// }

// export default FormComponents

import React, { useContext, useEffect, useRef, useState } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { loadingDocSummary } from '../../constant/documentstype'
const FormComponents = ({ onUpdateFormData }) => {
  const {
    dataDocumentToLint,
    isLoadingForm,
    updatedDataDocumentToLint,
    activeTag,
    resumeTags,
    handleCheckFormEmpty,
  } = useContext(DocsContext)
  const [formData, setFormData] = useState({ data: {} })
  const [labelToForm, setLabelToForm] = useState([])
  const formRef = useRef(null)

  useEffect(() => {
    if (activeTag) {
      const hasActiveTagToForm = resumeTags.find(
        (item) => item.tag === activeTag.part
      )
      setLabelToForm(hasActiveTagToForm?.itens || [])
    }
  }, [activeTag, resumeTags])

  useEffect(() => {
    // console.log("dataDocumentToLint", dataDocumentToLint)
    const hasDataIa = dataDocumentToLint?.find(
      (item) =>
        item.part === activeTag?.part && item.numberTag === activeTag?.numberTagShowForm
    )
    // console.log("hasDataIa", hasDataIa)

    if (hasDataIa) {
      const normalizedData = normalizeKeys(hasDataIa.data)
      if (JSON.stringify(formData.data) !== JSON.stringify(normalizedData)) {
        setFormData({ data: normalizedData })
        onUpdateFormData({ data: normalizedData })
      }
    }
  }, [dataDocumentToLint, activeTag, onUpdateFormData])

  const normalizeKeys = (data) => {
    const normalizedData = {}
    Object.keys(data).forEach((key) => {
      normalizedData[key.toLowerCase()] = data[key]
    })
    return normalizedData
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        data: {
          ...prevFormData.data,
          [name.toLowerCase()]: value,
        },
      }
      onUpdateFormData(updatedFormData)
      return updatedFormData
    })

    updatedDataDocumentToLint(name.toLowerCase(), value)
  }

  useEffect(() => {
    const checkFormEmpty = Object.values(formData.data).every(
      (value) => value === ''
    )
    handleCheckFormEmpty(checkFormEmpty)
  }, [formData, handleCheckFormEmpty])

  return isLoadingForm ? (
    <S.LoadingList>
      {loadingDocSummary.map((item, idx) => (
        <S.LoadingListItem key={idx}>
          {item}
          <label></label>
          <p></p>
        </S.LoadingListItem>
      ))}
    </S.LoadingList>
  ) : (
    <S.Form ref={formRef}>
      {labelToForm.map((doc, index) => (
        <S.WrapperInput key={index}>
          <S.Label>{doc.label}</S.Label>
          <S.Input
            type="text"
            name={doc.key.toLowerCase()}
            disabled={
              activeTag?.tagsValidated?.find(
                (tag) => tag.numberTag === activeTag?.numberTagShowForm
              )?.tagValidated
            }
            value={formData.data[doc.key.toLowerCase()] || ''}
            onChange={handleInputChange}
            style={{
              cursor: activeTag?.tagsValidated?.find(
                (tag) => tag.numberTag === activeTag?.numberTagShowForm
              )?.tagValidated
                ? 'not-allowed'
                : 'auto',
            }}
          />
        </S.WrapperInput>
      ))}
    </S.Form>
  )
}

export default FormComponents
