import styled, { css } from 'styled-components'

export const Section = styled.section`
  background-color: #fff;
`
export const SectionVertical = styled.section`
  background-color: #fff;
`

export const MainPage = styled.main`
overflow-y: auto;
  @media (min-width: 1024px) {
    display: flex;
    height: 94.4vh;
    width: 100%;
  }
`

export const Docs = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
    border-right: 1px solid #eee;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const DocInfo = styled.div`
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    display: flex;
    height: 94.4vh;
    width: 100%;
    overflow-y: ${({showForm}) => showForm ? 'scroll' : 'auto'};
  }
`
export const panelResizeHandleStyles = css`
  background-color: red;
  width: 3px;
`

export const StyledPanelResizeHandle = styled.div`
  ${panelResizeHandleStyles}
`
