import React, { useContext, useState, useEffect, useRef } from 'react'
import * as S from './styles'
import CopyDataImage from '../../assets/image/copy2.svg'
import { ReactComponent as Error } from '../../assets/image/error-circle.svg'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as ArrowRight } from '../../assets/image/double-arrow.svg'
import CopyValue from '../../assets/image/copy-ok.svg'
import insertSpaceBetweenWords from '../../utils/utils'
import PropagateLoader from 'react-spinners/PropagateLoader'
import ModalAllListDataDocuments from '../modal-all-list-data-documents/ModalAllListDataDocuments'

const override = {
  display: 'block',
  margin: '2rem 0',
  borderColor: 'red',
}

const DocumentListComponent = () => {
  const [validatedDocument, setValidatedDocument] = useState({})
  //eslint-disable-next-line no-unused-vars
  const [loadingDocument, setLoadingDocument] = useState(true)
  const {
    idFileDoc,
    validationDocument,
    configData,
    showForm,
    analyzerDataList,
    handleOpenModalAllLists,
    loadingDocumentsData,
    startingLoading,
    errorDocBox,
  } = useContext(DocsContext)
  const [copiedText, setCopiedText] = useState('')
  const [loadingById, setLoadingById] = useState('loading')
  const [documentsInfo, setDocumentsInfo] = useState({})
  const [openCopyButton, setOpenCopyButton] = useState(false)
  const [isUndefinedOnLoad, setIsUndefinedOnLoad] = useState(false)
  const [moreDataList, setMoreDataList] = useState()
  const timeoutRef = useRef(null)

  const color = '#ccc'
  const loading = true

  // const findDocumentById = (id) => {
  //   let foundDocument = analyzerDataList?.find(
  //     (item) => item?.retorno && item?.retorno.idDocBox === id
  //   )

  //   if (!foundDocument) {
  //     foundDocument = { retorno: { dados: {} } }
  //   }

  //   return foundDocument
  // }

  // const foundDocument = findDocumentById(idFileDoc)

  useEffect(() => {
    if (!analyzerDataList || analyzerDataList.length === 0) {
      // console.log('analyzerDataList ainda não carregado ou está vazio')
      return
    }

    // console.log('analyzerDataList:', analyzerDataList)

    // Log para verificar cada item da lista
    // analyzerDataList.forEach((item, index) => {
    //   console.log(`Item ${index}:`, item);
    //   console.log(`Item retorno idDocBox:`, item?.retorno?.idDocBox);
    // });

    const foundDocument = analyzerDataList.find(
      (item) => item?.retorno?.idDocBox === idFileDoc
    )

    if (foundDocument) {
      // console.log('Documento encontrado:', foundDocument)
      setDocumentsInfo(foundDocument?.retorno.dados || {})
      setMoreDataList(foundDocument.retorno.listas || [])
      if (
        foundDocument?.retorno.dados &&
        Object.keys(foundDocument.retorno.dados).length === 0
      ) {
        // console.log('retorno.dados é vazio:', foundDocument.retorno.dados)
        setLoadingById('vazio')
        return
      } else {
        // console.log('retorno.dados preenchido:', foundDocument.retorno.dados)
        setLoadingById('finished')
      }
    } else {
      setLoadingById('error')
      // console.warn('Documento não encontrado para idFileDoc:', idFileDoc)
    }

    // setLoadingDocument(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idFileDoc, analyzerDataList])

  useEffect(() => {
    const documentToValidated = configData?.documents.find(
      (item) => item.documentId === idFileDoc
    )
    setValidatedDocument(documentToValidated)
  }, [idFileDoc, configData?.documents])

  // useEffect(() => {
  //   if (!foundDocument) {
  //     const foundId = errorDocBox.find((docId) => docId === idFileDoc)
  //     if (foundId) {
  //       setIsUndefinedOnLoad(true)
  //     }
  //   }

  //   if (foundDocument) {
  //     setIsUndefinedOnLoad(false)
  //   }
  // }, [idFileDoc, analyzerDataList, foundDocument, errorDocBox])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const copyToClipboard = (text) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedText(text)
        setOpenCopyButton(true)

        timeoutRef.current = setTimeout(() => {
          setOpenCopyButton(false)
          setCopiedText(null)
        }, 950)
      })
      .catch((err) => {
        //console.error('Falha ao copiar texto: ', err);
      })
  }

  const hasListData =
    moreDataList?.retorno?.listas &&
    Object.keys(moreDataList.retorno.listas).length > 0

  useEffect(() => {
    // console.log('loadingDocumentsData', loadingDocumentsData)
    // console.log('documentsInfo', documentsInfo)
  }, [loadingDocumentsData])

  return (
    <>
      <S.Hr showForm={showForm} validationDocument={validationDocument} />
      <S.DataTitle
        validationDocument={validationDocument}
        docValidated={validatedDocument?.validated}
      >
        <h2>DADOS</h2>
        <hr />
      </S.DataTitle>
      {loadingDocumentsData[idFileDoc] === 'loading' ? (
        <S.AnimatedContainer isActive={loadingDocumentsData[idFileDoc] === 'loading'}>
          <S.LoadingComponent>
            {Array.from({ length: 8 }).map((_, index) => (
              <S.LoadingComponentItem key={index}>
                <p></p>
                <button></button>
              </S.LoadingComponentItem>
            ))}
          </S.LoadingComponent>
        </S.AnimatedContainer>
      ) : loadingDocumentsData[idFileDoc] === 'vazio' ? (
        <S.AnimatedContainer isActive={loadingDocumentsData[idFileDoc] === 'vazio'}>
          <S.ContentNoInfo>
            <S.Content>
              <p>
                <span>
                  <Error />
                </span>
                Esta classificação não contém dados disponíveis para extração.
              </p>
            </S.Content>
          </S.ContentNoInfo>
        </S.AnimatedContainer>
      ) : loadingDocumentsData[idFileDoc] === 'error' ? (
        <S.AnimatedContainer isActive={loadingDocumentsData[idFileDoc] === 'error'}>
          <S.ContentNoInfo>
            <S.Content>
              <p>
                <span>
                  <Error />
                </span>
                Ocorreu um erro ao tentar classificar o documento. Por favor,
                tente novamente.
              </p>
            </S.Content>
          </S.ContentNoInfo>
        </S.AnimatedContainer>
      ) : (
        documentsInfo &&
        Object.keys(documentsInfo).length > 0 && (
          <S.AnimatedContainer  isActive={loadingDocumentsData[idFileDoc] === 'finished'}>
          <S.DataList
            validatedDocument={validatedDocument}
            validationDocument={validationDocument}
            validated={validatedDocument?.validated}
          >
            {Object.entries(documentsInfo)
              ?.filter(([key]) => !/hidden/i.test(key))
              .sort(([keyA], [keyB]) => keyA?.localeCompare(keyB))
              .map(([key, value], index) => (
                <S.DataListItem key={index}>
                  <p>
                    <strong>{insertSpaceBetweenWords(key || '')}:</strong>
                    <span>{value}</span>
                  </p>
                  <S.ConfirmCopyValue
                    openCopyButton={openCopyButton}
                    visible={copiedText === value}
                  >
                    <img src={CopyValue} alt="valor copiado" />
                    Copiado
                  </S.ConfirmCopyValue>
                  <S.BtnCopy
                    title={'Copiar'}
                    onClick={() => copyToClipboard(value)}
                  >
                    <img src={CopyDataImage} alt="icon" />
                  </S.BtnCopy>
                </S.DataListItem>
              ))}
            {hasListData && (
              <>
                <S.BtnOpenDataDocs
                  onClick={() => {
                    handleOpenModalAllLists()
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  VER MAIS
                  <S.SeeMore>
                    <ArrowRight />
                  </S.SeeMore>
                </S.BtnOpenDataDocs>
                <ModalAllListDataDocuments
                  additionalInformation={moreDataList.retorno.listas}
                />
              </>
            )}
          </S.DataList>
          </S.AnimatedContainer>
        )
      )}
    </>
  )
}

export default DocumentListComponent
