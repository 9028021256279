import React from 'react'
import { render } from 'react-dom'
import { MsalProvider } from '@azure/msal-react'

import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { getAppInsights, reactPlugin } from './insights/app-insights'
import msalInstance from './msal/msalInstance'

const appInsights = getAppInsights()
const TrackedApp = withAITracking(reactPlugin, App)

msalInstance
  .initialize()
  .then(() => {
    const AppProvider = () => (
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>{appInsights ? <TrackedApp /> : <App />}</BrowserRouter>
      </MsalProvider>
    )

    const container = document.getElementById('root')
    render(<AppProvider />, container)
  })
  .catch((error) => {
    console.error('Erro ao inicializar o MSAL:', error)
  })
