// import styled, { keyframes } from 'styled-components'

// export const Section = styled.section`
//   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//   width: 100%;
//   /* min-height: 18px; */
//   /* min-height: ${({ showProgrees, validationDocument }) => {
//     if (!validationDocument) return '80px'
//     return showProgrees ? '100px' : '80px'
//   }}; */
//   min-height: 80px;
//   display: ${({ showProgrees, validationDocument }) => {
//     if (!validationDocument) return 'none'
//     return showProgrees ? 'flex' : 'none'
//   }};
//   /* display: flex; */
//   align-items: center;
//   justify-content: center;
// `

// export const Container = styled.div`
//   width: 92%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

// export const DocsCountValidated = styled.section`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   justify-content: ${({ showProgrees, validationDocument }) => {
//     if (!validationDocument) return 'flex-end'
//     return showProgrees ? 'space-between' : 'flex-end'
//   }};
//   align-items: center;
//   padding: 0;
//   @media (max-width: 600px) {
//     margin: ${({ allDocsValidated }) => (allDocsValidated ? '0 0 2rem' : '0')};
//     flex-direction: ${({ allDocsValidated }) =>
//       allDocsValidated ? 'column' : 'row'};
//   }
// `

// export const ContentButton = styled.div`
//   @media (max-width: 600px) {
//     display: flex;
//     flex-direction: row;
//   }
// `

// export const SectionProgressBar = styled.section`
//   /* display: flex; */
//   display: ${({ showProgrees, validationDocument }) => {
//     if (!validationDocument) return 'none'
//     return showProgrees ? 'flex' : 'none'
//   }};
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: space-between;
//   flex: 1;
//   padding: ${({ allDocsValidated }) => (allDocsValidated ? '0 1rem 0 0' : '0')};
//   /* width: ${({ allDocsValidated }) => (allDocsValidated ? '72%' : '100%')}; */
//   transition: width 0.5s ease-in, transform 0.5s ease-in;
//   /* transform: ${({ allDocsValidated }) =>
//     allDocsValidated ? 'scaleX(0.98)' : 'scaleX(1)'}; */
//   transform-origin: left;
//   @media (max-width: 600px) {
//     padding: ${({ allDocsValidated }) =>
//       allDocsValidated ? '2rem 0 1rem' : '0'};
//     width: 100%;
//   }
// `

// // export const Button = styled.button`
// //   background: #a6151c;
// //   position: ${({ allDocsValidated }) =>
// //     allDocsValidated ? 'static' : 'fixed'};
// //   border: none;
// //   color: #fff;
// //   border-radius: 8px;
// //   cursor: pointer;
// //   font-family: Museo700sans, sans-serif;
// //   font-size: 0.9rem;
// //   letter-spacing: 0.4px;
// //   height: 2.5rem;
// //   display: flex;
// //   align-items: center;
// //   justify-content: center;
// //   width: ${({ allDocsValidated }) => (allDocsValidated ? '100px' : '0px')};
// //   opacity: ${({ allDocsValidated }) => (allDocsValidated ? 1 : 0)};
// //   visibility: ${({ allDocsValidated }) =>
// //     allDocsValidated ? 'visible' : 'hidden'};
// //   transition: width 0.2s ease-in, opacity 0s ease-in, visibility 0.2s ease-in,
// //     background-color 0.01s ease-in;
// //   transition-delay: ${({ allDocsValidated }) =>
// //     allDocsValidated ? '0.3s' : '0s'};
// //   &:active {
// //     transform: scale(0.98);
// //   }
// //   &:hover {
// //     background-color: rgb(145 14 20);
// //     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
// //   }
// // `

// export const Button = styled.button`
//   background: #a6151c;
//   position: ${({ allDocsValidated }) =>
//     allDocsValidated ? 'static' : 'fixed'};
//   border: none;
//   color: #fff;
//   border-radius: 8px;
//   cursor: pointer;
//   font-family: Museo700sans, sans-serif;
//   font-size: 0.9rem;
//   letter-spacing: 0.4px;
//   height: 2.5rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   width: ${({ allDocsValidated }) =>
//     allDocsValidated ? '100px' : '0px'};

//   transition:
//     width 0.3s ease,       /* Largura leva 0.5s para diminuir */
//     background-color 0.3s ease,
//     visibility 0.3s ease,   /* A visibilidade também muda suavemente */
//     opacity 0.3s ease;      /* Controla a opacidade durante a transição */

//   opacity: ${({ allDocsValidated }) =>
//     allDocsValidated ? 1 : 0};

//   /* A visibilidade só desaparece depois que a largura se torna 0 */
//   visibility: ${({ allDocsValidated }) =>
//     allDocsValidated ? 'visible' : 'hidden'};

//   transition-delay: ${({ allDocsValidated }) =>
//     allDocsValidated ? '0.2s' : '0.5s'};

//   &:active {
//     transform: scale(0.98);
//   }

//   &:hover {
//     background-color: rgb(145, 14, 20);
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//   }
// `

// export const ProgressBar = styled.div`
//   /* width: ${({ allDocsValidated }) =>
//     allDocsValidated ? '100%' : '100%'}; */
//   width: 100%;
//   height: 18px;
//   /* background-color: #f0f0f0; */
//   border-radius: 12px;
//   transition: width 0.5s ease-in, transform 0.3s ease-in;
//   /* transform: ${({ allDocsValidated }) =>
//     allDocsValidated ? 'scaleX(0.94)' : 'scaleX(1)'}; */
//   transform-origin: left;
//   border: 1px solid rgba(0, 0, 0, 0.1);
//   background-color: rgb(247, 247, 247);
//   box-shadow: inset rgba(0, 0, 0, 0.2) 0px 2px 4px,
//     inset rgba(255, 255, 255, 0.2) 0px -2px 4px;
//   background: linear-gradient(
//     180deg,
//     rgba(200, 200, 200, 0.4),
//     rgba(160, 160, 160, 0.2) 50%,
//     rgba(200, 200, 200, 0.4) 100%
//   );
// `

// const fillAnimation = keyframes`
//   from {
//     width: 0;
//   }
//   to {
//     width: ${(props) => props.progressWidth}%;
//   }
// `

// export const ProgressFill = styled.div`
//   height: 100%;
//   border-radius: 7px;

//   width: ${(props) => props.progressWidth}%;
//   transition: width 1s linear, background-color 1s ease;
//   background-color: ${({ allDocsValidated }) =>
//     allDocsValidated ? 'rgb(156, 166, 36)' : '#a6151c'};
//     box-shadow: ${({ allDocsValidated }) =>
//     allDocsValidated
//       ? `0 2px 5px rgba(0, 0, 0, 0.2), /* Sombra externa suave */
//          inset 0 2px 3px rgba(255, 255, 255, 0.2), /* Luz interna na parte superior */
//          inset 0 -2px 3px rgba(0, 0, 0, 0.2)` /* Sombra interna na parte inferior */
//       : 'none'};

//   border-radius: 8px; /* Suaviza as bordas para dar mais suavidade ao efeito */
//   transition: background-color 0.3s ease, box-shadow 0.3s ease;

//   /* animation: ${fillAnimation} 2s ease-out forwards;  */
// `

// export const ProgressBarData = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   width: 100%;
//   align-items: baseline;
//   padding: 0.6rem 0 0;
//   p {
//     color: #626262;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 8px;
//     /* font-family: 'Museo300'; */
//   }
// `

// export const ContentProgressBarData = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 96%;
// `

// export const ContentMaxWidth = styled.div`
//   display: flex;
//   @media (max-width: 600px) {
//     display: none;
//   }
// `

// export const ContentMinWidth = styled.div`
//   display: none;
//   @media (max-width: 600px) {
//     display: flex;
//   }
// `

import styled, { keyframes } from 'styled-components'

export const Section = styled.section`
  border-bottom: 1px solid rgb(234 234 234);
  width: 100%;
  /* min-height: 18px; */
  min-height: ${({ showProgrees, validationDocument }) => {
    if (!validationDocument) return '80px'
    return showProgrees ? '90px' : '80px'
  }};
  display: ${({ showProgrees, validationDocument, showForm }) => {
    if(showForm) return 'flex';
    return showProgrees ? 'flex' : 'none'
  }};
  /* display: flex; */
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1000px) {
    width: 94%;
  }
`

export const DocsCountValidated = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-content: ${({ showProgrees, validationDocument }) => {
    return showProgrees ? 'space-between' : 'flex-end'
  }};
  align-items: center;
  padding: 0;
  @media (max-width: 600px) {
    margin: ${({ allDocsValidated }) => (allDocsValidated ? '0 0 2rem' : '0')};
    flex-direction: ${({ allDocsValidated }) =>
      allDocsValidated ? 'column' : 'row'};
  }
`

export const ContentButton = styled.div`
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
  }
`

export const SectionProgressBar = styled.section`
  /* display: flex; */
  display: ${({ showForm, showProgrees, validationDocument }) => {
    if(showForm) return 'flex'
    return showProgrees ? 'flex' : 'none'
  }};
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  padding: ${({ allDocsValidated }) => (allDocsValidated ? '0 1rem 0 0' : '0')};
  /* width: ${({ allDocsValidated }) => (allDocsValidated ? '72%' : '100%')}; */
  transition: width 0.5s ease-in, transform 0.5s ease-in;
  /* transform: ${({ allDocsValidated }) =>
    allDocsValidated ? 'scaleX(0.98)' : 'scaleX(1)'}; */
  transform-origin: left;
  @media (max-width: 600px) {
    padding: ${({ allDocsValidated }) =>
      allDocsValidated ? '2rem 0 1rem' : '0'};
    width: 100%;
  }
`

export const Button = styled.button`
  background: #a6151c;
  position: ${({ allDocsValidated }) =>
    allDocsValidated ? 'static' : 'fixed'};
  border: none;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-family: Museo700sans, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 0.4px;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ allDocsValidated }) => (allDocsValidated ? '100px' : '0px')};
  opacity: ${({ allDocsValidated }) => (allDocsValidated ? 1 : 0)};
  visibility: ${({ allDocsValidated }) =>
    allDocsValidated ? 'visible' : 'hidden'};
  transition: width 0.4s ease-out, opacity 0s, visibility 0.1s ease,
    background-color 0.01s ease-in;
  transition-delay: ${({ allDocsValidated }) =>
    allDocsValidated ? '0.2s' : '0s'};
  &:active {
    transform: scale(0.98);
  }
  &:hover {
    background-color: rgb(145 14 20);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`

export const ProgressBar = styled.div`
  /* width: ${({ allDocsValidated }) =>
    allDocsValidated ? '100%' : '100%'}; */
  width: 100%;
  height: 20px;
  /* background-color: #f0f0f0; */
  border-radius: 8px;
  transition: width 0.3s ease-in, transform 0.3s ease-in;
  /* transform: ${({ allDocsValidated }) =>
    allDocsValidated ? 'scaleX(0.94)' : 'scaleX(1)'}; */
  transform-origin: left;
  border: 1px solid rgb(204, 204, 204);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgb(247, 247, 247);
  box-shadow: inset rgba(0, 0, 0, 0.2) 0px 2px 4px,
    inset rgba(255, 255, 255, 0.2) 0px -2px 4px;
  background: linear-gradient(
    180deg,
    rgba(200, 200, 200, 0.4),
    rgba(160, 160, 160, 0.2) 50%,
    rgba(200, 200, 200, 0.4) 100%
  );
`

const fillAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: ${(props) => props.progressWidth}%;
  }
`

export const ProgressFill = styled.div`
  height: 100%;
  border-radius: 8px;
  width: ${(props) => props.progressWidth}%;
  transition: width 2s linear, background-color 1s ease;
  background-color: ${({ allDocsValidated }) =>
    allDocsValidated ? 'rgb(156, 166, 36)' : '#a6151c'};
  box-shadow: ${({ allDocsValidated }) =>
    allDocsValidated
      ? `0 2px 5px rgba(0, 0, 0, 0.2), /* Sombra externa suave */
         inset 0 2px 3px rgba(255, 255, 255, 0.2), /* Luz interna na parte superior */
         inset 0 -2px 3px rgba(0, 0, 0, 0.2)` /* Sombra interna na parte inferior */
      : 'none'};
    box-shadow: none;

  /* animation: ${fillAnimation} 2s ease-out forwards;  */
`

export const ProgressBarData = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  padding: 0.6rem 0 0;
  p {
    color: #626262;
    font-weight: 500;
    font-size: 12px;
    line-height: 8px;
    /* font-family: 'Museo300'; */
  }
`

export const ContentProgressBarData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96%;
`

export const ContentMaxWidth = styled.div`
  display: flex;
  @media (max-width: 600px) {
    display: none;
  }
`

export const ContentMinWidth = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: flex;
  }
`
