import React, { useContext } from 'react'
import * as S from './styles'
import Logo from '../../assets/image/logo_multiplan_header.svg'
import { ReactComponent as Back } from '../../assets/image/back-to-main.svg'
import { ReactComponent as Close } from '../../assets/image/turnoff4.svg'
// import { useHistory } from 'react-router-dom'
import { DocsContext } from '../../context/docs-context'

const Header = () => {
  const {
    openDocumentPreview,
    handlePreviewDocsReview,
    showLabelCloseButton,
    configId,
  } = useContext(DocsContext)

  const handleClose = () => {
    // console.log('fechou')
    document.cookie = `analyzer_view_${configId}=close;domain=.multiplan.com.br`
  }

  return (
    <S.Header openDocumentPreview={openDocumentPreview}>
      <S.Content>
        <S.Logo className="logo">
          <img src={Logo} alt="logo" />
        </S.Logo>
        {showLabelCloseButton && (
          <S.BtnClose title={'Fechar a página'} onClick={handleClose}>
            <Close />
          </S.BtnClose>
        )}
        {openDocumentPreview ? (
          <S.BtnBack
            title={'Voltar a página inicial'}
            onClick={handlePreviewDocsReview}
          >
            <Back />
          </S.BtnBack>
        ) : null}
      </S.Content>
    </S.Header>
  )
}

export default Header
