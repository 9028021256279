import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DocsProvider } from './context/docs-context'
import { EventType } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import FetchInterceptor from './interceptor/fetchInterceptor'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify'
import LoaderInitial from './components/loader-initial'
import Routes from './routes/routes'
import Header from './components/header/Header'
import GlobalStyle from './GlobalStyle'
import './App.css'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { getAppInsights, reactPlugin } from './insights/app-insights'

const App = () => {
  const { instance } = useMsal()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const appInsights = getAppInsights()
  const location = useLocation()

  useEffect(() => {
    appInsights.trackPageView({ name: location.pathname })
  }, [appInsights, location])

  useEffect(() => {
    if (instance) {
      setIsLoading(true)
      instance.handleRedirectPromise().then((authResult) => {
        const account = instance.getActiveAccount()

        if (account) {
          setIsAuthenticated(true)
          const { username } = account
          appInsights.setAuthenticatedUserContext(username)
          instance.setActiveAccount(account)

          if (authResult) {
            localStorage.setItem('token', authResult?.idToken)
            localStorage.setItem(
              'tokenExp',
              authResult?.account?.idTokenClaims?.exp.toString()
            )
          }
        } else {
          appInsights.clearAuthenticatedUserContext()
          instance.loginRedirect()
        }
      })
      .catch((error) => {
        console.error('Error handling redirect promise:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })

      const callbackId = instance.addEventCallback((event) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          const account = event.payload.account
          instance.setActiveAccount(account)
          setIsAuthenticated(true)
        }
      })

      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId)
        }
      }
    }
  }, [appInsights, instance])

  if (isLoading) {
    return (
      <>
        <GlobalStyle />
        <LoaderInitial />
      </>
    )
  }

  if(!isAuthenticated) {
    return(
      <>
        <GlobalStyle />
        <LoaderInitial />
      </>
    )
  }

  return (
    <>
      <GlobalStyle />
      <FetchInterceptor>
        <DocsProvider>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Header />
          <Routes />
        </DocsProvider>
      </FetchInterceptor>
    </>
  )
}

export default withAITracking(reactPlugin, App)
