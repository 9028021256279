import styled from 'styled-components'

export const Section = styled.div`
  width: 100%;
  margin: ${({ validationDocument, showListDocuments }) => {
    if (!validationDocument && !showListDocuments) return '0.5rem 0 0.8rem'
    if (!validationDocument && showListDocuments) return '0.5rem 0 0.8rem'
    return '1rem 0 1rem'
  }};
  display: ${({ showClassification }) =>
    showClassification ? 'flex' : 'none'};
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media (max-width: 500px) {
  }
`

export const ClassificationDoctype = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: flex-start;
  position: relative;
  flex-wrap: wrap;
  h2 {
    color: var(--text-color);
    font-size: var(--font-primary);
    font-weight: bold;
    font-family: 'Museo700sans';
    letter-spacing: 0.5px;
  }
`

export const NavDocumentTypeList = styled.nav`
  position: relative;
  flex: 1;
  display: flex;
`

export const SearchDocuments = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90%;
  display: ${({ openSearch }) => (openSearch ? 'flex' : 'none')};
  align-items: center;
  justify-content: flex-start;
  border: none;
  opacity: ${({ openSearch }) => (openSearch ? '0.8' : '0.3')};
  padding: 0 0.2rem;
  input {
    width: 100%;
    display: flex;
    height: 2.1rem;
    background-color: transparent;
    color: #373737;
    border: none;
    align-items: center;
    font-size: 0.9rem;
    padding: 0 0.6rem;
    font-family: 'Museo700sans', sans-serif;
    &::placeholder {
      color: #b0b3b7;
      padding: 0 0.2rem;
      font-size: 0.9rem;
      font-family: 'Museo700sans', sans-serif;
    }
    &:active {
      border: none;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
`

export const ButtonConfirmDoc = styled.button`
  flex: ${({ validationDocument }) => (validationDocument ? '1' : 'none')};
  position: relative;
  background: none;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-wrap: ${({ validationDocument }) =>
    validationDocument ? 'nowrap' : 'wrap'};
  width: auto;
  max-width: ${({ validationDocument }) =>
    validationDocument ? '100%' : '80%'};
  height: 2.3rem;
  justify-content: ${({ openSearch }) =>
    openSearch ? 'flex-end' : 'space-between'};
  padding: ${({ validationDocument }) =>
    validationDocument ? '0 0.65rem' : '0rem 1rem'};
  margin: 0 0 0 0.5rem;
  align-items: center;
  font-size: 0.9rem;
  color: ${({
    dataValidated,
    openSearch,
    validationDocument,
    validatedDocument,
    showForm,
    loadingDocumentsData,
  }) => {
    if (loadingDocumentsData === 'loading') return '#B0B3B7'
    if (openSearch) return '#B0B3B7'
    if (showForm) {
      return dataValidated ? '#B0B3B7' : '#a6151c'
    }
    if (validatedDocument) {
      return validationDocument ? '#B0B3B7' : '#a6151c'
    }

    return '#a6151c'
  }};
  /* color: ${({ openSearch, validationDocument, validatedDocument }) => {
    if (openSearch) return 'var(--placeholder-color)'
    return !validationDocument
      ? 'var(--text-color)'
      : validatedDocument //olhar depois a cor nao ta certa quando ta validado
      ? 'var(--placeholder-color)'
      : 'var(--primary-color)'
  }}; */
  font-size: var(--font-primary);
  font-family: 'Museo700sans', sans-serif;
  /* cursor: ${({ validatedDocument, validationDocument, showForm }) =>
    showForm
      ? 'auto'
      : validationDocument === false
      ? 'auto'
      : validatedDocument
      ? 'not-allowed'
      : 'pointer'}; */
  cursor: ${({
    validatedDocument,
    validationDocument,
    showForm,
    loadingDocumentsData,
  }) =>
    showForm || validationDocument === false
      ? 'auto'
      : loadingDocumentsData === 'loading' || validatedDocument
      ? 'not-allowed'
      : 'pointer'};
  transition: transform 0.4s ease;
  span {
    position: relative;
    right: 0;
    top: 0.1rem;
  }
  img {
    display: ${({ validationDocument }) =>
      !validationDocument ? 'none' : 'flex'};
  }
  svg {
    width: 1rem;
    height: 1rem;
  }
  svg path {
    fill: ${({ loadingDocumentsData, disabled }) =>
      loadingDocumentsData === 'loading' || disabled ? '#B0B3B7' : '#a6151c'};
  }
  p {
    padding: 0 0.4rem 0 0;
  }
  /* p {
    opacity: ${({ openSearch }) => (openSearch ? '0.3' : '1')};
  } */
  /* p {
    opacity: ${({ clearTextButton }) => (clearTextButton ? '1' : '0')};
  } */
  &:active {
    transform: ${({ validatedDocument, validationDocument, showForm }) => {
      if (!validationDocument) return 'none'
      if (showForm) return 'none'
      return validatedDocument ? 'none' : 'scale(0.98)'
    }};
  }

  &:hover {
    box-shadow: ${({ validatedDocument, validationDocument }) =>
      validatedDocument || validationDocument === false
        ? 'none'
        : '0 2px 4px rgba(0, 0, 0, 0.04)'};
  }
  @media (min-height: 1500px) {
    height: 4.5vh;
  }
`

export const NoClassificationText = styled.li`
  color: #999;
  font-style: italic;
  text-align: center;
  padding: 0 0.5rem;
`

export const NoClassification = styled.button`
  font-family: 'Museo500sans';
  color: #fff;
  font-size: 0.9rem;
  background-color: #b0b3b7;
  border: 1px solid ${({ color }) => (color ? color : '#B0B3B7')};
  padding: 0rem 0.6rem;
  height: 2.2rem;
  border-radius: 8px;
  margin: 0 0 0 0.5rem;
`

export const DocumentList = styled.ul`
  display: ${({ validationDocument }) =>
    validationDocument ? 'flex' : 'none'};
  flex-direction: column;
  height: ${({ getListDocTypes, length, searchQuery }) => {
    if (!getListDocTypes) return '0'
    if (length <= 0 && searchQuery !== '') return '65px'
    if (length >= 6) return '240px'
    if (length === 5) return '200px'
    if (length === 4) return '160px'
    if (length === 3) return '120px'
    if (length === 2) return '82px'
    if (length === 1) return '42px'
    return '0'
  }};
  transition: height 0.3s ease-in;
  align-items: flex-start;
  justify-content: space-evenly;
  position: absolute;
  top: 40px;
  left: 7px;
  width: 98%;
  z-index: 99;
  background: #fff;
  border: ${({ getListDocTypes }) =>
    getListDocTypes ? '1px solid #ccc' : 'none'};
  border-radius: 8px;
  padding: ${({ getListDocTypes, length }) => {
    if(getListDocTypes && length > 6) return '0.2rem 0.2rem'
    return getListDocTypes ? '0rem 0.2rem 0rem' : '0 1rem'}};
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 0 20px 20px 0;
    background-color: #e8e8e8;
    /* background: linear-gradient(
      0deg,
      rgba(244, 244, 244, 0.5) 0%,
      rgba(255, 250, 250, 0.5) 100%
    ); */
  }

  &::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 30px;
    border: 2px solid #e8e8e8;
  }
`

export const DocumentListItem = styled.li`
  opacity: ${({ getListDocTypes }) =>
    getListDocTypes ? '1' : '0'}; /* Começa com opacidade 0 */
  padding: 0.6rem;
  width: 100%;
  color: var(--primary-color);
  font-size: var(--font-secondary);
  font-family: 'Museo500sans';
  border-bottom: 1px solid #ccc;
  transition: opacity 0.5s ease;
  cursor: pointer;
  position: relative;
  &:nth-child(n) {
    transition-delay: ${({ getListDocTypes }) =>
      getListDocTypes ? '0s' : '0.1s'};
  }

  transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); // easeOutQuart
  -webkit-mask-image: -webkit-radial-gradient(red, black); // force */

  &:hover {
    color: #fff;
    border-radius: 4px;
  }

  &:last-child {
    border-bottom: none;
  }
`

export const FillContainer = styled.div`
  position: absolute;
  left: 0%;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #a6151c;
  border-radius: 4px;
  transition: transform 0.8s; // Altera a transição para aplicar apenas ao transform */
  transform: translateX(-100%);
  z-index: -99999;

  ${DocumentListItem}:hover & {
    transform: translateX(0);
  }
`
// export const ModalOverlay = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   background: rgba(0, 0, 0, 0.6); /* Cor do fundo com opacidade */
//   display: flex;
//   justify-content: center;
//   align-items: flex-start; /* Alinhamento ao topo */
//   z-index: 1000; // Certifique-se de que o modal fique acima de outros conteúdos
// `

// export const ModalContainer = styled.div`
//   position: absolute;
//   background: #f4f4f4;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
//   border-radius: 8px;
//   width: 100%;
//   z-index: 1010;
//   top: 0%;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: stretch;
//   @media (max-width: 1022px) {
//     position: fixed;
//     top: 50%;
//     left: 5%;
//     width: 90%;
//   }
// `

export const ResumeDocs = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.3rem;
  width: 2.4rem;
  border-radius: 8px;
  border: 1px solid rgb(204, 204, 204);
  background-color: transparent;
  cursor: pointer;
  display: ${({ validationDocument, showResumeDocToContract }) => {
    if (!showResumeDocToContract) return 'none'
    return validationDocument ? 'none' : 'flex'
  }};
  align-items: center;
  justify-content: center;

  svg {
    width: 1.3rem;
    height: 1.3rem;
  }
  svg path {
    fill: #878787;
  }
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
  }
  &:active {
    transform: scale(0.98);
  }
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`

export const ModalContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 8px;
  background-color: #f4f4f4;
  z-index: 999999;
  width: 98%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  background: #f4f4f4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  width: 96%;
`

export const ContentBox = styled.div`
  /* width: 20px; */
  border-radius: 8px 0 0 8px;
  background-color: #a6151c;
`

export const ModalContent = styled.div`
  padding: 1rem;
  width: 100%;
  z-index: 999999;
  strong {
    font-family: 'Museo500sans';
    font-size: 0.9rem;
  }
  h3 {
    text-transform: uppercase;
    color: rgb(166, 21, 28);
    font-family: Inter, sans-serif;
    margin: 0.5rem 0 1.5rem;
    font-size: 1rem;
    font-weight: 800;
  }
  button {
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 5px;
    height: 2.2rem;
    padding: 0 0.4rem;
    font-family: Inter, sans-serif;
    margin: 0 0.5rem 0 0;
    &:active {
      transform: 'scale(0.98)';
    }
  }
`

export const Classification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
