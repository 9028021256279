import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import DocumentNotValidate from '../document-not-validate/DocumentNotValidate'
import ValidatedDocument from '../validated-document/ValidatedDocument'
import { ReactComponent as CheckList } from '../../assets/image/checklist.svg'
import FilterByContractingParts from '../filter-by-contracting-parts/FilterByContractingParts'
import { DocsContext } from '../../context/docs-context'

const DocumentValidation = () => {
  const {
    idFileDoc,
    activeTag,
    configData,
    validationDocument,
    showForm,
    disableValidationButton,
    handleOpenModalValidatedDocuments,
    showFilterButton,
  } = useContext(DocsContext)
  const [validatedDocument, setValidatedDocument] = useState({})

  useEffect(() => {
    // console.log("<FilterByContractingParts />", showFilterButton)
    const documentToValidated = configData?.documents?.find(
      (item) => item?.documentId === idFileDoc
    )
    setValidatedDocument(documentToValidated)
  }, [idFileDoc, configData?.documents])

  return (
    <S.Section disableValidationButton={disableValidationButton}>
      <S.Validation validatedDocument={validatedDocument?.validated}>
        <S.Content
          showForm={showForm}
          validationDocument={validationDocument}
          dataValidated={
            activeTag?.tagsValidated?.find(
              (tag) => tag.numberTag === activeTag?.numberTagShowForm
            )?.tagValidated
          }
          validatedDocument={validatedDocument?.validated}
          disableValidationButton={disableValidationButton}
        >
          <S.Title>
            <div>
              <h1>DOCUMENTO</h1>
              {/* {!showForm ? (
                validationDocument ? (
                  validatedDocument?.validated ? (
                    <h1>VALIDADO</h1>
                  ) : (
                    <h1>NÃO VALIDADO</h1>
                  )
                ) : null
              ) : null} */}
              {!disableValidationButton &&
                !showForm &&
                (validationDocument ? (
                  validatedDocument?.validated ? (
                    <h1>VALIDADO</h1>
                  ) : (
                    <h1>NÃO VALIDADO</h1>
                  )
                ) : null)}
            </div>
            <hr />
          </S.Title>
          {/* {validationDocument ? (
            <S.ConfirmDocumentValidate
              validationDocument={validationDocument}
              validated={validatedDocument?.validated}
            >
              <S.CircleBox></S.CircleBox>
              <span>
                {validatedDocument?.validated ? (
                  <ValidatedDocument />
                ) : (
                  <DocumentNotValidate />
                )}
              </span>
            </S.ConfirmDocumentValidate>
          ) : null} */}
          {!disableValidationButton && !showForm && validationDocument ? (
            <S.ConfirmDocumentValidate
              validationDocument={validationDocument}
              validated={validatedDocument?.validated}
            >
              <S.CircleBox></S.CircleBox>
              <span>
                {validatedDocument?.validated ? (
                  <ValidatedDocument />
                ) : (
                  <DocumentNotValidate />
                )}
              </span>
            </S.ConfirmDocumentValidate>
          ) : null}
        </S.Content>
        {/* <FilterByContractingParts /> */}
        {!showForm && showFilterButton && <FilterByContractingParts />}
        {showForm && (
          <S.BtnCheckList
            onClick={handleOpenModalValidatedDocuments}
            title={'Resumir Documentos'}
          >
            <CheckList />
          </S.BtnCheckList>
        )}
        {/* {showFilterButton && <FilterByContractingParts />} */}
      </S.Validation>
    </S.Section>
  )
}

export default DocumentValidation
