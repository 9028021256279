import { createContext, useEffect, useState, useCallback } from 'react'
import { useFetch } from '../interceptor/fetchInterceptor'
import { defaultColors } from '../constant/documentstype'
import { toast } from 'react-toastify'
import { groupDocuments } from '../utils/utils'
import { getAppInsights } from '../insights/app-insights'

export const DocsContext = createContext()

const { Provider } = DocsContext

export const DocsProvider = (props) => {
  const fetchWithAuth = useFetch()
  const [collection, setCollection] = useState([])
  const fileId = collection[0]
  const [loadingDocumentsData, setLoadingDocumentsData] = useState([{}])
  const [configId, setConfigId] = useState('')
  const [isLoadingForm, setIsLoadingForm] = useState(false)
  const [isFormDataEmpty, setIsFormDataEmpty] = useState(true)
  const [validatedDocument, setValidatedDocument] = useState(false)
  const [combinededDocuments, setCombinededDocuments] = useState([])
  const [dataDocumentToLint, setDataDocumentToLint] = useState([])
  const [idFileDoc, setIdFileDoc] = useState('')
  const [totalValidateDocs, setTotalValidateDocs] = useState(0)
  const [allDocsValidated, setAllDocsValidated] = useState(false)
  const [openModalSendDocs, setOpenModalSendDocs] = useState(false)
  const [typeDocuments, setTypeDocuments] = useState('')
  const [contractPartsSelected, setContractPartsSelected] = useState([])
  const [filteredPartsContract, setFilteredPartsContract] = useState()
  const [filterSelectedColor, setFilterselectedcolor] = useState()
  const [analyzerDataList, setAnalyzerDataList] = useState([])
  const [confirmEditValuesDocLInt, setConfirmEditValuesDocLInt] = useState([])
  const [configData, setConfigData] = useState(null)
  const [documentListData, setDocumentListData] = useState([])
  const [classificationListDocs, setClassificationListDocs] = useState([])
  const [contractPartsDoc, setContractPartsDoc] = useState([])
  const [docSelected, setDocSelected] = useState()
  const [progressBar, setProgressBar] = useState(true)
  const [previewSize, setPreviewSize] = useState([])
  const [boxToken, setBoxToken] = useState('')
  const [boxTokenTemp, setBoxTokenTemp] = useState('')
  const [boxTokenExp, setBoxTokenExp] = useState()
  const [timer, setTimer] = useState(0)
  const [colorsByDocumentId, setColorsByDocumentId] = useState({})
  const [tagsWithColors, setTagsWithColors] = useState([])
  const [documentsPartArray, setDocumentsPartArray] = useState([])
  const [previewBoxVisibility, setPreviewBoxVisibility] = useState()
  const [showClassification, setShowClassification] = useState()
  const [showContractPart, setShowContractPart] = useState()
  const [allDataValidated, setAllDataValidated] = useState([])
  const [validationDocument, setValidationDocument] = useState(false)
  const [disableValidationButton, setDisableValidationButton] = useState(false)
  const [showLabelText, setShowLabelText] = useState()
  const [showLabelTag, setShowLabelTag] = useState(false)
  const [labelTag, setLabelTag] = useState('')
  const [showListDocuments, setShowListDocuments] = useState()
  const [openModalAllLists, setOpenModalAllLists] = useState(false)
  const [showError, setShowError] = useState()
  const [canDownloaded, setCanDownloaded] = useState()
  const [canPrinted, setCanPrinted] = useState()
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false)
  const [errorDocBox, setErrorDocBox] = useState([])
  const [submitDocList, setSubmitDocList] = useState([])
  const [activeTag, setActiveTag] = useState([])
  const [showForm, setShowForm] = useState(undefined)
  const [partSelected, setPartSelected] = useState({})
  const [showFilterButton, setShowFilterButton] = useState()
  const [showLabelCloseButton, setShowLabelCloseButton] = useState()
  const [validatedDocumentsModalOpen, setValidatedDocumentsModalOpen] =
    useState()
  const [tagRequiredFile, setTagRequiredFile] = useState()
  const [startingLoading, setStartingLoading] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState()
  const [showResumeDocToContract, setShowResumeDocToContract] = useState()
  const [resumeTags, setResumeTags] = useState([])
  const [partFormListState, setPartFormListState] = useState([])

  const appInsights = getAppInsights()

  const handleOpenModalValidatedDocuments = () => {
    setValidatedDocumentsModalOpen(!validatedDocumentsModalOpen)
  }

  useEffect(() => {
    const updatedDocuments = configData?.documents?.map((doc) => {
      const { suggested, ...rest } = doc
      return rest
    })

    setConfigData((prevConfigData) => ({
      ...prevConfigData,
      documents: updatedDocuments,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(window.location.search)
      const configId = queryParams.get('id')
      setConfigId(configId)
      // console.log('Config ID:', configId)

      if (configId) {
        try {
          // console.log('Iniciando requisição para obter configuração...')
          const response = await fetchWithAuth(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/configIds/?id=${configId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )

          if (!response.ok) {
            setShowError('Página não encontrada ou acesso não autorizado.')
            throw new Error(`Erro na resposta: ${response.statusText}`)
          }

          const data = await response.json()
          // console.log('Dados recebidos da configuração:', data)
          const index = tagsWithColors?.length % defaultColors.length

          const tagLabelFromData = {
            id: tagsWithColors?.length,
            color: defaultColors[index].color,
            part: data?.labelTag,
            numberTag: null,
          }
          //"1575070820683","1602139173017"
          const idDocumentBox = data.documents.map((item) => item.documentId)
          // console.log('Documentos recebidos:', idDocumentBox)
          // console.log('documentListData', documentListData)

          setConfigData(data)
          setShowForm(data.showForm)
          setCollection(idDocumentBox)
          setProgressBar(data.showProgressBar)
          setShowContractPart(data.showContractPart)
          setShowClassification(data.showClassification)
          // console.log({data: data.showValidationButton});
          setValidationDocument(data.showValidationButton)
          setDisableValidationButton(data.disableValidationButton)
          setTagRequiredFile(data.tagRequired)
          setShowLabelText(data.showLabelText)
          setBoxToken(data.boxToken)
          setShowListDocuments(data.showListDocuments)
          setBoxTokenExp(data.boxTokenExp)
          setClassificationListDocs(data.classifications)
          setContractPartsDoc(data.tags)
          setPreviewSize([80, 20])
          // console.log("showFilterButton", data.showFilterButton)
          setShowFilterButton(data.showFilterButton)
          // setPreviewSize([data.startPreviewSize, 100 - data.startPreviewSize])
          setDocumentListData(data.documents)
          setPreviewBoxVisibility(data.showPreviewDoc)
          setShowLabelTag(data.showLabelTag)
          setCanDownloaded(data.showCanDownload)
          setCanPrinted(data.showCanPrint)
          setLabelTag(tagLabelFromData)
          setResumeTags(data.resumeTags)
          setShowDeleteButton(data.showDeleteButton)
          setShowResumeDocToContract(data.showResumeDocToContract)
          setShowLabelCloseButton(data.showLabelCloseButton)
          if (idDocumentBox.length > 0 && !data.showForm) {
            setIdFileDoc(idDocumentBox[0])
            // console.log('Documento inicial selecionado:', idDocumentBox[0])

            if (idFileDoc) {
              const selectedItem = analyzerDataList?.find(
                (item) => item?.retorno.idDocBox === idFileDoc
              )
              setDocSelected(selectedItem)
              // console.log('Documento selecionado:', selectedItem)
            }
          }
          // console.log("data classification:", data.documents)
          // setDataDocumentToLint(data.documents.map((item) => ({
          //   part: item.part,
          //   numberTag: item.numberTag ?? 1,
          //   data: {}
          // })));
          // console.log('quem ta em resumetags levanta a mão', data.resumeTags)
          // setDataDocumentToLint(
          //   Array.from(
          //     new Map(
          //       data.documents.map((item) => [
          //         `${item.part}-${item.numberTag ?? 1}`,
          //         { part: item.part, numberTag: item.numberTag ?? 1, data: {} },
          //       ])
          //     ).values()
          //   )
          // )

          setDataDocumentToLint(
            Array.from(
              new Map(
                data.documents.map((item) => {
                  // Defina a chave de mapeamento com `part` e `numberTag` (ou `1` como padrão)
                  const key = `${item.part}-${item.numberTag ?? 1}`

                  // Encontre o item correspondente em `resumeTags` para `part`
                  const resumeTagItem = data?.resumeTags.find(
                    (tag) => tag.tag === item.part
                  )

                  // console.log("Analisando item:", item);
                  // console.log("Resume tag item correspondente::", resumeTagItem);

                  // Inicialize `data` como um objeto vazio
                  let dataContent = {}

                  // Caso `resumeTagItem` e `resumeTagItem.items` existam
                  if (resumeTagItem?.itens) {
                    resumeTagItem.itens.forEach((itemObj) => {
                      // console.log("Analisando item dentro de items:", itemObj);

                      // Verifique se o `itemObj.value` existe e é um array
                      if (itemObj.values) {
                        // console.log('ENTRA AQUI?????', item)
                        // console.log('itemObj', itemObj)
                        // Encontre o valor específico com `numberTag` correspondente
                        const matchingValue = itemObj.values.find(
                          (valueObj) =>
                            parseInt(valueObj.numberTag) ===
                            (item.numberTag ?? 1)
                        )

                        // console.log(
                        //   'Valor correspondente encontrado:',
                        //   matchingValue
                        // )

                        // Se houver `matchingValue`, atualize `dataContent`
                        if (matchingValue) {
                          dataContent = {
                            ...dataContent,
                            [itemObj?.key]: matchingValue?.value,
                          }
                        }
                      }
                    })
                  }

                  return [
                    key,
                    {
                      part: item.part,
                      numberTag: item.numberTag ?? 1,
                      data: dataContent || {},
                    },
                  ]
                })
              ).values()
            )
          )

          startInterval()

          // const fetchAnalyzerData = data.documents.map(({ documentId }) =>
          //   fetchWithAuth(
          //     `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/documentDetails?idDocBox=${documentId}`,
          //     {
          //       method: 'GET',
          //       headers: {
          //         'Content-Type': 'application/json',
          //       },
          //     }
          //   )
          //     .then((response) => {
          //       if (!response.ok) {
          //         throw new Error(
          //           `Erro na resposta do analisador: ${response.statusText}`
          //         )
          //       }
          //       return response.json()
          //     })
          //     .then((data) => {
          //       console.log(
          //         'Dados do analisador recebidos para documento',
          //         documentId,
          //         ':',
          //         data
          //       )
          //       return data
          //     })
          //     .catch((error) => {
          //       // setLoadingStatus('error')
          //       console.error('Erro ao buscar dados:', error)
          //     })
          // )

          // const analyzerDataResults = (
          //   await Promise.allSettled(fetchAnalyzerData)
          // ).map((item) => {
          //   if (item.status === 'fulfilled') {
          //     const result = item.value
          //     // console.log("result", result)
          //     return result
          //   } else {
          //     console.log('veio aqui???')
          //     console.error(`Erro ao buscar analyzer data:`, item.reason)
          //     return { retorno: { dados: {} } }
          //   }
          // })

          // console.log("analyzerDataResults", analyzerDataResults)
          // setAnalyzerDataList(analyzerDataResults)

          // const fetchAnalyzerData = async () => {
          //   try {
          //     console.log("ta aqui?")
          //     const fetchPromises = data.documents.map(async ({ documentId }) => {
          //       try {
          //         const response = await fetchWithAuth(
          //           `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/documentDetails?idDocBox=${documentId}`,
          //           {
          //             method: 'GET',
          //             headers: {
          //               'Content-Type': 'application/json',
          //             },
          //           }
          //         );

          //         if (!response.ok) {
          //           throw new Error(
          //             `Erro na resposta do analisador (ID: ${documentId}): ${response.statusText}`
          //           );
          //         }

          //         const responseData = await response.json();
          //         console.log(
          //           'Dados do analisador recebidos para documento',
          //           documentId,
          //           ':',
          //           responseData
          //         );
          //         return responseData;
          //       } catch (error) {
          //         console.error(`Erro ao buscar dados para o documento ${documentId}:`, error);
          //         return { retorno: { dados: {} } }; // Retorna um objeto padrão para evitar falhas
          //       }
          //     });

          //     // Aguarda todas as requisições serem resolvidas
          //     const analyzerDataResults = await Promise.allSettled(fetchPromises);
          //     console.log('analyzerDataResults', analyzerDataResults);

          //     // Define o estado com os dados resolvidos
          //     setAnalyzerDataList(analyzerDataResults);
          //     setLoadingDocumentsData("finished")
          //   } catch (error) {
          //     console.error('Erro geral ao buscar dados do analisador:', error);
          //   }
          // };

          // fetchAnalyzerData();
          const fetchAnalyzerData = async () => {
            try {
              // console.log("Iniciando fetchAnalyzerData");

              // Inicializa o estado de carregamento para todos os documentos
              const initialLoadingState = data.documents.reduce(
                (acc, { documentId }) => {
                  acc[documentId] = 'loading'
                  return acc
                },
                {}
              )
              setLoadingDocumentsData(initialLoadingState)

              // Mapeia os documentos para criar promessas de fetch
              const fetchPromises = data.documents.map(
                async ({ documentId }) => {
                  try {
                    const response = await fetchWithAuth(
                      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/documentDetails?idDocBox=${documentId}`,
                      {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      }
                    )

                    if (!response.ok) {
                      throw new Error(
                        `Erro na resposta do analisador (ID: ${documentId}): ${response.statusText}`
                      )
                    }

                    const responseData = await response.json()
                    // console.log(
                    //   'Dados do analisador recebidos para documento',
                    //   documentId,
                    //   ':',
                    //   responseData
                    // );

                    // Verifica se os dados retornados estão vazios
                    const isEmpty =
                      !responseData?.retorno?.dados ||
                      Object.keys(responseData.retorno.dados).length === 0

                    // Atualiza o estado de carregamento conforme o resultado
                    setLoadingDocumentsData((prev) => ({
                      ...prev,
                      [documentId]: isEmpty ? 'vazio' : 'finished',
                    }))

                    return { documentId, value: responseData }
                  } catch (error) {
                    console.error(
                      `Erro ao buscar dados para o documento ${documentId}:`,
                      error
                    )

                    // Atualiza o estado para "error" em caso de falha
                    setLoadingDocumentsData((prev) => ({
                      ...prev,
                      [documentId]: 'error',
                    }))

                    return { documentId, error: error.message }
                  }
                }
              )

              // Aguarda todas as requisições serem resolvidas
              const analyzerDataResults = await Promise.all(fetchPromises)

              // console.log('analyzerDataResults:', analyzerDataResults);

              // Processa os resultados e atualiza o estado com os dados
              const processedResults = analyzerDataResults.map((result) => {
                if (result.error) {
                  return { retorno: { dados: {}, idDocBox: result.documentId } } // Valor padrão para erros
                } else {
                  return result.value
                }
              })

              setAnalyzerDataList(processedResults)
            } catch (error) {
              console.error('Erro geral ao buscar dados do analisador:', error)
            }
          }

          // Chamar a função
          fetchAnalyzerData()
        } catch (error) {
          console.error('Erro ao buscar dados:', error)
        }
      }
    }

    fetchData()
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [fetchWithAuth])

  // useEffect(() => {
  //   _keepConfigIdUp(configId)
  //   /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  // }, [timer])

  const startInterval = () => {
    // console.log('Iniciando intervalo para atualizar timer')
    setInterval(() => {
      setTimer(new Date().getTime())
      // console.log('Timer atualizado:', new Date().getTime())
    }, 1000 * 60 * 3) // 3 minutos
    // }, 10000); // 10 segundos - debug
  }

  // const _keepConfigIdUp = (configId) => {
  //   const refreshBoxToken =
  //     boxTokenExp == null || boxTokenExp <= new Date().getTime() ? true : false
  //   const body = { refreshBoxToken: refreshBoxToken }
  //   fetchWithAuth(
  //     `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/refreshConfig/?id=${configId}`,
  //     {
  //       method: 'POST',
  //       body: JSON.stringify(body),
  //       headers: { 'Content-Type': 'application/json' },
  //     }
  //   )
  //     .then((data) => {
  //       console.log("DATAAAAAA", data)
  //       return data.json()
  //     })
  //     .then((data) => {
  //       if (refreshBoxToken) {
  //         setBoxTokenTemp(data.boxToken)
  //         setBoxTokenExp(data.boxTokenExp)
  //       }
  //     })
  // }

  const _keepConfigIdUp = useCallback(
    async (configId) => {
      try {
        const refreshBoxToken =
          boxTokenExp == null || boxTokenExp <= new Date().getTime()
            ? true
            : false
        const body = { refreshBoxToken: refreshBoxToken }

        const response = await fetchWithAuth(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/refreshConfig/?id=${configId}`,
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
          }
        )

        if (!response.ok) {
          let errorMsg = `Erro ${response.status}: ${response.statusText}`
          try {
            const errorData = await response.json()
            if (errorData.msg) {
              errorMsg = errorData.msg
            }
          } catch (e) {}
          throw new Error(errorMsg)
        }

        const contentType = response.headers.get('content-type')
        let data = null
        if (contentType && contentType.includes('application/json')) {
          data = await response.json()
        }

        if (refreshBoxToken && data) {
          setBoxTokenTemp(data.boxToken)
          setBoxTokenExp(data.boxTokenExp)
        }
      } catch (error) {
        console.error('Erro ao atualizar configId:', error.message || error)
      }
    },
    [boxTokenExp, fetchWithAuth]
  )

  useEffect(() => {
    if (configId) {
      _keepConfigIdUp(configId)
    }
  }, [_keepConfigIdUp, configId, timer])

  const downloadDocument = async (idFileDoc) => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${boxToken}` },
    }
    try {
      const response = await fetch(
        `https://api.box.com/2.0/files/${idFileDoc}?fields=download_url`,
        requestOptions
      )
      const result = await response.json()
      window.location.href = result?.download_url
    } catch (error) {
      // console.log(error)
      toast.error(
        `Falha no download do documento. Por favor, tente novamente.`,
        {
          theme: 'light',
        }
      )
    }
  }

  const checkToken = () => {
    // console.log('checkToken chamado, boxTokenTemp:', boxTokenTemp)
    if (!!boxTokenTemp) {
      setBoxToken(null)
      setTimeout(() => {
        setBoxToken(boxTokenTemp)
        // console.log('Token atualizado para:', boxTokenTemp)
      }, 50)
      setBoxTokenTemp(null)
    }
  }

  useEffect(() => {
    // console.log('dataDocumentToLint', dataDocumentToLint)
  }, [dataDocumentToLint])

  // useEffect(() => {
  //   const formTagdData = configData?.documents?.reduce((acc, doc) => {
  //     const numberTag = doc.numberTag || 1

  //     if (doc.part) {
  //       const existingGroupIndex = acc.findIndex(
  //         (group) => group.part === doc.part
  //       )
  //       const filterResumeTagByPart = resumeTags.find(
  //         (item) => item.tag === doc.part
  //       )

  //       console.log("filterResumeTagByPart", filterResumeTagByPart)

  //       const tagValidated =
  //         filterResumeTagByPart?.tagsValidated?.find(
  //           (tag) => tag.numberTag === numberTag
  //         )?.tagValidated

  //       if (existingGroupIndex !== -1) {
  //         const updatedGroup = { ...acc[existingGroupIndex] }

  //         updatedGroup.documentId = [...updatedGroup.documentId, doc.documentId]

  //         const tagGroupIndex = updatedGroup.documentIdByNumberTag.findIndex(
  //           (tag) => tag.numberTag === numberTag
  //         )

  //         if (tagGroupIndex !== -1) {
  //           updatedGroup.documentIdByNumberTag[tagGroupIndex] = {
  //             ...updatedGroup.documentIdByNumberTag[tagGroupIndex],
  //             documentsId: [
  //               ...updatedGroup.documentIdByNumberTag[tagGroupIndex]
  //                 .documentsId,
  //               doc.documentId,
  //             ],
  //           }
  //         } else {
  //           updatedGroup.documentIdByNumberTag = [
  //             ...updatedGroup.documentIdByNumberTag,
  //             {
  //               numberTag: numberTag,
  //               documentsId: [doc.documentId],
  //             },
  //           ]
  //         }

  //         const validatedTagIndex = updatedGroup.tagsValidated.findIndex(
  //           (tag) => tag.numberTag === numberTag
  //         )

  //         if (validatedTagIndex === -1) {
  //           updatedGroup.tagsValidated = [
  //             ...updatedGroup.tagsValidated,
  //             { numberTag: numberTag, tagValidated: tagValidated },
  //           ]
  //         }

  //         acc[existingGroupIndex] = updatedGroup
  //       } else {
  //         acc.push({
  //           part: doc.part,
  //           numberTag: numberTag,
  //           documentIdByNumberTag: [
  //             {
  //               numberTag: numberTag,
  //               documentsId: [doc.documentId],
  //             },
  //           ],
  //           tagsValidated: [
  //             { numberTag: numberTag, tagValidated: tagValidated },
  //           ],
  //           documentId: [doc.documentId],
  //           dataValidated: false,
  //         })
  //       }
  //     }

  //     return acc
  //   }, [])

  //   console.log('FOOOOOOOOORRRRRMMMMMM', formTagdData) // Exibe o array final

  //   if (formTagdData) {
  //     setPartFormListState(formTagdData)
  //   }
  // }, [configData, resumeTags])

  // useEffect(() => {
  //   const formTagdData = configData?.documents?.reduce((acc, doc) => {
  //     const numberTag = doc.numberTag || 1

  //     if (doc.part) {
  //       const existingGroupIndex = acc.findIndex(
  //         (group) => group.part === doc.part
  //       )
  //       const filterResumeTagByPart = resumeTags.find(
  //         (item) => item.tag === doc.part
  //       )

  //       console.log("filterResumeTagByPart", filterResumeTagByPart)

  //       const tagValidated =
  //         filterResumeTagByPart?.tagsValidated?.find(
  //           (tag) => tag.numberTag === numberTag
  //         )?.tagValidated

  //       if (existingGroupIndex !== -1) {
  //         const updatedGroup = { ...acc[existingGroupIndex] }

  //         updatedGroup.documentId = [...updatedGroup.documentId, doc.documentId]

  //         const tagGroupIndex = updatedGroup.documentIdByNumberTag.findIndex(
  //           (tag) => tag.numberTag === numberTag
  //         )

  //         if (tagGroupIndex !== -1) {
  //           updatedGroup.documentIdByNumberTag[tagGroupIndex] = {
  //             ...updatedGroup.documentIdByNumberTag[tagGroupIndex],
  //             documentsId: [
  //               ...updatedGroup.documentIdByNumberTag[tagGroupIndex]
  //                 .documentsId,
  //               doc.documentId,
  //             ],
  //           }
  //         } else {
  //           updatedGroup.documentIdByNumberTag = [
  //             ...updatedGroup.documentIdByNumberTag,
  //             {
  //               numberTag: numberTag,
  //               documentsId: [doc.documentId],
  //             },
  //           ]
  //         }

  //         const validatedTagIndex = updatedGroup.tagsValidated.findIndex(
  //           (tag) => tag.numberTag === numberTag
  //         )

  //         if (validatedTagIndex === -1) {
  //           updatedGroup.tagsValidated = [
  //             ...updatedGroup.tagsValidated,
  //             { numberTag: numberTag, tagValidated: tagValidated },
  //           ]
  //         }

  //         acc[existingGroupIndex] = updatedGroup
  //       } else {
  //         acc.push({
  //           part: doc.part,
  //           numberTag: numberTag,
  //           documentIdByNumberTag: [
  //             {
  //               numberTag: numberTag,
  //               documentsId: [doc.documentId],
  //             },
  //           ],
  //           tagsValidated: [
  //             { numberTag: numberTag, tagValidated: tagValidated },
  //           ],
  //           documentId: [doc.documentId],
  //           dataValidated: false,
  //         })
  //       }
  //     }

  //     return acc
  //   }, [])

  //   console.log('FOOOOOOOOORRRRRMMMMMM', formTagdData) // Exibe o array final

  //   if (formTagdData) {
  //     setPartFormListState(formTagdData)
  //   }
  // }, [configData, resumeTags])

  useEffect(() => {
    if (!resumeTags || resumeTags.length === 0) {
      // console.log("Aguardando dados de resumeTags...");
      return
    }

    // const formTagData = configData?.documents?.reduce((acc, doc) => {
    //   const numberTag = doc.numberTag || 1
    //   // console.log('Documento atual:', doc)
    //   // console.log('Número da Tag (numberTag):', numberTag)

    //   if (!doc.part) return

    //   const existingGroupIndex = acc.findIndex(
    //     (group) => group.part === doc.part
    //   )
    //   // console.log('Índice do grupo existente:', existingGroupIndex)

    //   const filterResumeTagByPart = resumeTags.find(
    //     (item) => item.tag === doc.part
    //   )
    //   // console.log(
    //   //   "Correspondência em resumeTags para 'part':",
    //   //   doc.part,
    //   //   resumeTags
    //   // )
    //   // console.log('Resultado de filterResumeTagByPart:', filterResumeTagByPart)

    //   const tagValidated =
    //     filterResumeTagByPart?.tagsValidated?.find(
    //       (tag) => tag.numberTag === numberTag
    //     )?.tagValidated || false

    //   // console.log('Valor de tagValidated obtido:', tagValidated)

    //   if (existingGroupIndex !== -1) {
    //     const updatedGroup = { ...acc[existingGroupIndex] }

    //     updatedGroup.documentId = [...updatedGroup.documentId, doc.documentId]
    //     // console.log(
    //     //   'documentId atualizado no grupo existente:',
    //     //   updatedGroup.documentId
    //     // )
    //     const tagGroupIndex = updatedGroup.documentIdByNumberTag.findIndex(
    //       (tag) => tag.numberTag === numberTag
    //     )
    //     // console.log("Índice da tag dentro de documentIdByNumberTag:", tagGroupIndex);

    //     if (tagGroupIndex !== -1) {
    //       updatedGroup.documentIdByNumberTag[tagGroupIndex] = {
    //         ...updatedGroup.documentIdByNumberTag[tagGroupIndex],
    //         documentsId: [
    //           ...updatedGroup.documentIdByNumberTag[tagGroupIndex].documentsId,
    //           doc.documentId,
    //         ],
    //       }
    //       // console.log("documentIdByNumberTag atualizado:", updatedGroup.documentIdByNumberTag);
    //     } else {
    //       // Adiciona novo número da tag se não existir
    //       updatedGroup.documentIdByNumberTag.push({
    //         numberTag: numberTag,
    //         documentsId: [doc.documentId],
    //       })
    //       // console.log("Novo item adicionado a documentIdByNumberTag:", updatedGroup.documentIdByNumberTag);
    //     }
    //     const validatedTagIndex = updatedGroup.tagsValidated.findIndex(
    //       (tag) => tag.numberTag === numberTag
    //     )
    //     // console.log("Índice de tagValidated dentro do grupo:", validatedTagIndex);

    //     if (validatedTagIndex === -1) {
    //       updatedGroup.tagsValidated.push({
    //         numberTag: numberTag,
    //         tagValidated: tagValidated,
    //       })
    //       // console.log("Nova tagValidated adicionada:", updatedGroup.tagsValidated);
    //     } else {
    //       updatedGroup.tagsValidated[validatedTagIndex].tagValidated =
    //         tagValidated
    //       // console.log(
    //       //   'tagValidated atualizada:',
    //       //   updatedGroup.tagsValidated[validatedTagIndex]
    //       // )
    //     }

    //     acc[existingGroupIndex] = updatedGroup
    //   } else {
    //     // console.log(
    //     //   'Criando novo grupo para part:',
    //     //   doc.part,
    //     //   'e número da tag:',
    //     //   numberTag
    //     // )

    //     acc.push({
    //       part: doc.part,
    //       numberTag: numberTag,
    //       documentIdByNumberTag: [
    //         {
    //           numberTag: numberTag,
    //           documentsId: [doc.documentId],
    //         },
    //       ],
    //       tagsValidated: [{ numberTag: numberTag, tagValidated: tagValidated }],
    //       documentId: [doc.documentId],
    //       dataValidated: false,
    //     })
    //     // console.log("Novo grupo criado:", acc[acc.length - 1]);
    //   }

    //   return acc
    // }, [])
    const formTagData = configData?.documents?.reduce((acc, doc) => {
      const numberTag = doc.numberTag || 1

      if (!doc.part) return acc // Retorne o acumulador se `part` não existir

      const existingGroupIndex = acc.findIndex(
        (group) => group.part === doc.part
      )

      const filterResumeTagByPart = resumeTags.find(
        (item) => item.tag === doc.part
      )

      const tagValidated =
        filterResumeTagByPart?.tagsValidated?.find(
          (tag) => tag.numberTag === numberTag
        )?.tagValidated || false

      if (existingGroupIndex !== -1) {
        const updatedGroup = { ...acc[existingGroupIndex] }

        updatedGroup.documentId = [...updatedGroup.documentId, doc.documentId]

        const tagGroupIndex = updatedGroup.documentIdByNumberTag.findIndex(
          (tag) => tag.numberTag === numberTag
        )

        if (tagGroupIndex !== -1) {
          updatedGroup.documentIdByNumberTag[tagGroupIndex] = {
            ...updatedGroup.documentIdByNumberTag[tagGroupIndex],
            documentsId: [
              ...updatedGroup.documentIdByNumberTag[tagGroupIndex].documentsId,
              doc.documentId,
            ],
          }
        } else {
          updatedGroup.documentIdByNumberTag.push({
            numberTag: numberTag,
            documentsId: [doc.documentId],
          })
        }

        const validatedTagIndex = updatedGroup.tagsValidated.findIndex(
          (tag) => tag.numberTag === numberTag
        )

        if (validatedTagIndex === -1) {
          updatedGroup.tagsValidated.push({
            numberTag: numberTag,
            tagValidated: tagValidated,
          })
        } else {
          updatedGroup.tagsValidated[validatedTagIndex].tagValidated =
            tagValidated
        }

        acc[existingGroupIndex] = updatedGroup
      } else {
        acc.push({
          part: doc.part,
          numberTag: numberTag,
          documentIdByNumberTag: [
            {
              numberTag: numberTag,
              documentsId: [doc.documentId],
            },
          ],
          tagsValidated: [{ numberTag: numberTag, tagValidated: tagValidated }],
          documentId: [doc.documentId],
          dataValidated: false,
        })
      }

      return acc // Retorne sempre o acumulador
    }, [])

    // console.log('FOOOOOOOOORRRRRMMMMMM') // Exibe o array final

    if (formTagData && showForm) {
      console.log('tem gente aqui?')
      setPartFormListState(formTagData)
      const teste = formTagData
        .filter((item) => item.part)
        .sort((a, b) => a?.part.localeCompare(b?.part))

      const firstItems = teste[0]

      // console.log('teste', teste)
      // console.log('firstItems', firstItems)
      // console.log('firstItems documentID', firstItems.documentId)

      if (partFormListState && firstItems) {
        // const firstDocumentId = firstItems.documentIdByNumberTag[0]?.documentsId
        // const firstItemActiveTag = firstItems.documentIdByNumberTag[0]?.numberTag
        // console.log('firstItemActiveTag', firstItemActiveTag)
        // console.log(`chamou aqui?`)

        const sortedByNumberTag = firstItems.documentIdByNumberTag.sort(
          (a, b) => a.numberTag - b.numberTag
        )

        // const firstDocumentId = sortedByNumberTag[0]?.documentsId
        const firstItemActiveTag = sortedByNumberTag[0]?.numberTag

        // console.log('firstItemActiveTag', firstItemActiveTag)
        // console.log('firstDocumentId', firstDocumentId)

        const updatedFirstItems = {
          ...firstItems,
          numberTagShowForm: firstItemActiveTag,
        }
        // console.log("ta vindo true activetag?", updatedFirstItems)
        setActiveTag(updatedFirstItems)
        setCollection(firstItems.documentIdByNumberTag[0]?.documentsId)
        // console.log(firstItems.documentIdByNumberTag[0]?.documentsId)
        setIdFileDoc(firstItems.documentIdByNumberTag[0]?.documentsId[0])
        if (activeTag) {
          // setIsLoading(false)
        }
        // setIsLoading(false)
      }
    }

    // const formTagData = configData?.documents?.reduce((acc, doc) => {
    //   const numberTag = doc.numberTag || 1;
    //   console.log("Documento atual:", doc);
    //   console.log("Número da Tag (numberTag):", numberTag);

    //   if (!doc.part) return acc;

    //   const existingGroupIndex = acc.findIndex((group) => group.part === doc.part);
    //   console.log("Índice do grupo existente:", existingGroupIndex);

    //   // Encontra a part correspondente no resumeTags para verificar tags validadas
    //   const filterResumeTagByPart = resumeTags.find((item) => item.tag === doc.part);
    //   console.log("Correspondência em resumeTags para 'part':", doc.part, resumeTags);
    //   console.log("Resultado de filterResumeTagByPart:", filterResumeTagByPart);

    //   // Verifica se há uma tagValidated no resumo encontrado
    //   const tagValidated = filterResumeTagByPart?.tagsValidated?.find(
    //     (tag) => tag.numberTag === numberTag
    //   )?.tagValidated || false;

    //   console.log("Valor de tagValidated obtido:", tagValidated);

    //   if (existingGroupIndex !== -1) {
    //     // Grupo já existe, então atualizamos o grupo existente
    //     const updatedGroup = { ...acc[existingGroupIndex] };

    //     // Atualiza documentId para incluir o documento atual
    //     updatedGroup.documentId = [...updatedGroup.documentId, doc.documentId];
    //     console.log("documentId atualizado no grupo existente:", updatedGroup.documentId);

    //     // Encontra o índice da tag dentro de documentIdByNumberTag para atualizar ou adicionar
    //     const tagGroupIndex = updatedGroup.documentIdByNumberTag.findIndex(
    //       (tag) => tag.numberTag === numberTag
    //     );
    //     console.log("Índice da tag dentro de documentIdByNumberTag:", tagGroupIndex);

    //     if (tagGroupIndex !== -1) {
    //       // Atualiza documentos existentes no número da tag correspondente
    //       updatedGroup.documentIdByNumberTag[tagGroupIndex] = {
    //         ...updatedGroup.documentIdByNumberTag[tagGroupIndex],
    //         documentsId: [
    //           ...updatedGroup.documentIdByNumberTag[tagGroupIndex].documentsId,
    //           doc.documentId,
    //         ],
    //       };
    //       console.log("documentIdByNumberTag atualizado:", updatedGroup.documentIdByNumberTag);
    //     } else {
    //       // Adiciona novo número da tag se não existir
    //       updatedGroup.documentIdByNumberTag.push({
    //         numberTag: numberTag,
    //         documentsId: [doc.documentId],
    //       });
    //       console.log("Novo item adicionado a documentIdByNumberTag:", updatedGroup.documentIdByNumberTag);
    //     }

    //     // Verifica ou adiciona a tagValidated ao grupo
    //     const validatedTagIndex = updatedGroup.tagsValidated.findIndex(
    //       (tag) => tag.numberTag === numberTag
    //     );
    //     console.log("Índice de tagValidated dentro do grupo:", validatedTagIndex);

    //     if (validatedTagIndex === -1) {
    //       updatedGroup.tagsValidated.push({
    //         numberTag: numberTag,
    //         tagValidated: tagValidated,
    //       });
    //       console.log("Nova tagValidated adicionada:", updatedGroup.tagsValidated);
    //     } else {
    //       // Atualiza tagValidated existente caso necessário
    //       updatedGroup.tagsValidated[validatedTagIndex].tagValidated = tagValidated;
    //       console.log("tagValidated atualizada:", updatedGroup.tagsValidated[validatedTagIndex]);
    //     }

    //     // Atualiza o grupo na lista acumulada
    //     acc[existingGroupIndex] = updatedGroup;
    //   } else {
    //     // Cria novo grupo se não existir
    //     console.log("Criando novo grupo para part:", doc.part, "e número da tag:", numberTag);

    //     acc.push({
    //       part: doc.part,
    //       numberTag: numberTag,
    //       documentIdByNumberTag: [
    //         {
    //           numberTag: numberTag,
    //           documentsId: [doc.documentId],
    //         },
    //       ],
    //       tagsValidated: [
    //         { numberTag: numberTag, tagValidated: tagValidated },
    //       ],
    //       documentId: [doc.documentId],
    //       dataValidated: false,
    //     });
    //     console.log("Novo grupo criado:", acc[acc.length - 1]);
    //   }

    //   return acc;
    // }, []);

    // console.log("Resultado final de formTagData:", formTagData);

    // if (formTagData) {
    //   setPartFormListState(formTagData)
    //   const teste = formTagData
    //     .filter((item) => item.part)
    //     .sort((a, b) => a?.part.localeCompare(b?.part))

    //   const firstItems = teste[0]

    //   // console.log('teste', teste)
    //   // console.log('firstItems', firstItems)
    //   // console.log('firstItems documentID', firstItems.documentId)

    //   if (partFormListState && firstItems) {
    //     // const firstDocumentId = firstItems.documentIdByNumberTag[0]?.documentsId
    //     // const firstItemActiveTag = firstItems.documentIdByNumberTag[0]?.numberTag
    //     // console.log('firstItemActiveTag', firstItemActiveTag)
    //     // console.log(`chamou aqui?`)

    //     const sortedByNumberTag = firstItems.documentIdByNumberTag.sort(
    //       (a, b) => a.numberTag - b.numberTag
    //     )

    //     const firstDocumentId = sortedByNumberTag[0]?.documentsId
    //     const firstItemActiveTag = sortedByNumberTag[0]?.numberTag

    //     // console.log('firstItemActiveTag', firstItemActiveTag)
    //     // console.log('firstDocumentId', firstDocumentId)

    //     const updatedFirstItems = {
    //       ...firstItems,
    //       numberTagShowForm: firstItemActiveTag,
    //     }
    //     console.log("ta vindo true activetag?", updatedFirstItems)
    //     setActiveTag(updatedFirstItems)
    //     setCollection(firstItems.documentIdByNumberTag[0]?.documentsId)
    //     setIdFileDoc(firstItems.documentIdByNumberTag[0]?.documentsId[0])
    //     if (activeTag) {
    //       // setIsLoading(false)
    //     }
    //     // setIsLoading(false)
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, resumeTags, showForm])

  // useEffect(() => {
  //   if(resumeTags.length > 0) {
  //     const formTagdData = configData?.documents?.reduce((acc, doc) => {
  //       const numberTag = doc.numberTag || 1;
  //       console.log("Documento atual:", doc);
  //       console.log("Número da tag:", numberTag);

  //       if (doc.part) {
  //         console.log("Parte do documento:", doc.part);

  //         // Encontra o grupo existente na lista acumulada
  //         const existingGroupIndex = acc.findIndex(
  //           (group) => group.part === doc.part
  //         );
  //         // Encontra a parte correspondente em resumeTags, se existir
  //         const filterResumeTagByPart = resumeTags.find(
  //           (item) => item.tag === doc.part
  //         );
  //         console.log("Aqui vem resumeTags", resumeTags)
  //         console.log("Parte correspondente em resumeTags:", filterResumeTagByPart);
  //         // Verifica se existe uma tag validada correspondente para o numberTag atual
  //         const tagValidated = filterResumeTagByPart?.tagsValidated?.some(
  //           (tag) => {
  //             const isMatch = tag.numberTag === numberTag && tag.tagValidated;
  //             console.log(`Comparando tags - NumberTag: ${tag.numberTag}, TagValidated: ${tag.tagValidated}, IsMatch: ${isMatch}`);
  //             return isMatch;
  //           }
  //         ) || doc.tagValidated || false;
  //         console.log("Valor de tagValidated determinado:", tagValidated);

  //         if (existingGroupIndex !== -1) {
  //           const updatedGroup = { ...acc[existingGroupIndex] };
  //           console.log("Grupo existente encontrado, atualizando...", updatedGroup);

  //           // Adiciona o documentId ao grupo existente
  //           updatedGroup.documentId = [...updatedGroup.documentId, doc.documentId];
  //           console.log("Document IDs atualizados no grupo:", updatedGroup.documentId);

  //           const tagGroupIndex = updatedGroup.documentIdByNumberTag.findIndex(
  //             (tag) => tag.numberTag === numberTag
  //           );

  //           if (tagGroupIndex !== -1) {
  //             // Atualiza o documentsId para o número da tag correspondente
  //             updatedGroup.documentIdByNumberTag[tagGroupIndex] = {
  //               ...updatedGroup.documentIdByNumberTag[tagGroupIndex],
  //               documentsId: [
  //                 ...updatedGroup.documentIdByNumberTag[tagGroupIndex].documentsId,
  //                 doc.documentId,
  //               ],
  //             };
  //             console.log("Document ID adicionado em documentIdByNumberTag:", updatedGroup.documentIdByNumberTag[tagGroupIndex]);
  //           } else {
  //             // Adiciona um novo grupo de documentsId para o numberTag específico
  //             updatedGroup.documentIdByNumberTag = [
  //               ...updatedGroup.documentIdByNumberTag,
  //               {
  //                 numberTag: numberTag,
  //                 documentsId: [doc.documentId],
  //               },
  //             ];
  //             console.log("Novo documentIdByNumberTag adicionado:", {
  //               numberTag: numberTag,
  //               documentsId: [doc.documentId],
  //             });
  //           }

  //           const validatedTagIndex = updatedGroup.tagsValidated.findIndex(
  //             (tag) => tag.numberTag === numberTag
  //           );

  //           if (validatedTagIndex === -1) {
  //             // Adiciona uma nova validação de tag se ainda não existir
  //             updatedGroup.tagsValidated = [
  //               ...updatedGroup.tagsValidated,
  //               { numberTag: numberTag, tagValidated: tagValidated },
  //             ];
  //             console.log("Nova validação de tag adicionada:", { numberTag: numberTag, tagValidated: tagValidated });
  //           } else {
  //             // Atualiza a tag validada existente com o valor correto
  //             updatedGroup.tagsValidated[validatedTagIndex] = {
  //               ...updatedGroup.tagsValidated[validatedTagIndex],
  //               tagValidated: tagValidated,
  //             };
  //             console.log("Tag existente atualizada em tagsValidated:", updatedGroup.tagsValidated[validatedTagIndex]);
  //           }

  //           acc[existingGroupIndex] = updatedGroup;
  //           console.log("Grupo atualizado com sucesso:", updatedGroup);
  //         } else {
  //           // Cria um novo grupo se a parte ainda não existir
  //           const newGroup = {
  //             part: doc.part,
  //             numberTag: numberTag,
  //             documentIdByNumberTag: [
  //               {
  //                 numberTag: numberTag,
  //                 documentsId: [doc.documentId],
  //               },
  //             ],
  //             tagsValidated: [
  //               { numberTag: numberTag, tagValidated: tagValidated },
  //             ],
  //             documentId: [doc.documentId],
  //             dataValidated: false,
  //           };
  //           acc.push(newGroup);
  //           console.log("Novo grupo criado e adicionado:", newGroup);
  //         }
  //       }

  //       return acc;
  //     }, []);

  //     console.log("FOOOOOOOOORRRRRMMMMMM", formTagdData); // Exibe o array final completo

  //     if (formTagdData) {
  //       setPartFormListState(formTagdData);
  //     }
  //   }
  // }, [configData, resumeTags]);

  useEffect(() => {
    if (!resumeTags || resumeTags.length === 0) {
      // console.log('Aguardando dados de resumeTags...')
      return
    }

    const fetchDataForm = async () => {
      setIsLoadingForm(true)

      if (configData?.documents) {
        const finalDocumentsArray = groupDocuments(
          configData.documents,
          tagsWithColors
        )
        // console.log("finalDocumentsArray após groupDocuments:", finalDocumentsArray);

        const groupedDocuments = finalDocumentsArray.reduce((acc, doc) => {
          const key = `${doc.part}-${doc.numberTag}`
          if (!acc[key]) {
            acc[key] = {
              id: Object.keys(acc).length + 1,
              part: doc.part,
              numberTag: doc.numberTag,
              documents: [],
              tagAdd: doc.tagAdd,
            }
            // console.log(`Novo grupo criado para key=${key}:`, acc[key]);
          }

          doc.documentId?.forEach((id) => {
            const docConfigData = configData.documents.find(
              (item) => item.documentId === id
            )
            const docAnalyzerData = analyzerDataList?.find(
              (item) => item?.retorno && item.retorno.idDocBox === id
            )

            if (docConfigData || docAnalyzerData) {
              acc[key].documents.push({
                documentId: id,
                documentData: {
                  configData: docConfigData || {},
                  analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
                },
              })
              // console.log(`Documento adicionado ao grupo ${key} com id=${id}:`, acc[key]);
            } else {
              // console.log(`Documento com id=${id} não encontrado no configData ou analyzerData.`);
            }
          })

          return acc
        }, {})

        // console.log("groupedDocuments após processamento inicial:", groupedDocuments);

        const otherDocuments = configData.documents.reduce((acc, doc) => {
          const docAnalyzerData = analyzerDataList?.find(
            (item) => item?.retorno && item.retorno.idDocBox === doc.documentId
          )

          acc.push({
            documentId: doc.documentId,
            documentData: {
              configData: doc || {},
              analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
            },
          })
          // console.log("Documento adicionado a otherDocuments:", doc.documentId);

          return acc
        }, [])

        // console.log("otherDocuments final:", otherDocuments);

        if (otherDocuments.length > 0) {
          groupedDocuments['Outros'] = {
            id: Object.keys(groupedDocuments).length + 1,
            part: 'Outros',
            numberTag: null,
            documents: otherDocuments,
          }
          // console.log("Grupo 'Outros' adicionado a groupedDocuments:", groupedDocuments['Outros']);
        }

        const finalArray = Object.values(groupedDocuments)
        // console.log("Array final após conversão de groupedDocuments:", finalArray);

        if (finalArray.length > 0) {
          const newCombinedDocuments = finalArray.map((doc) => {
            // console.log("Processando documento para newCombinedDocuments:", doc);

            const dadosList =
              doc.documents.flatMap((item) => {
                const dados = item.documentData.analyzerData.dados || {}
                const label =
                  item.documentData.configData.classification?.label ||
                  'Não classificado'

                return Object.entries(dados).map(([key, value]) => ({
                  key,
                  label,
                  value: value || 'Não cadastrado',
                }))
              }) || []

            const dadosSeparados = dadosList
              .filter(({ key }) => !/hidden/i.test(key))
              .map(({ key, value, label }) => ({
                key,
                label,
                value,
              }))

            // const numberTagValue = showForm ? doc.numberTagShowForm : doc.numberTag;
            const numberTagValue = doc.numberTag
            // console.log(`Definindo numberTag para part=${doc.part}:`, numberTagValue);

            return {
              part: doc.part,
              numberTag: numberTagValue,
              dados: dadosSeparados,
            }
          })
          // console.log("newCombinedDocuments após mapeamento:", newCombinedDocuments);

          const hasData = newCombinedDocuments.some(
            (item) => item.dados.length > 0
          )
          // console.log("Verificando se newCombinedDocuments possui dados:", hasData);

          if (hasData) {
            setCombinededDocuments(newCombinedDocuments)
            // console.log("newCombinedDocuments definido em estado:", newCombinedDocuments);
          } else {
            // console.log("Nenhum dado válido encontrado em newCombinedDocuments.");
          }
          setIsLoadingForm(false)
        }
      } else {
        // console.log("ConfigData não encontrado ou está vazio.");
        setIsLoadingForm(false)
      }
    }

    fetchDataForm()
  }, [configData, tagsWithColors, showForm, analyzerDataList, resumeTags])

  /**
   *
   * aqui acaba os useeffect de requisicao
   */

  useEffect(() => {}, [showForm, partFormListState])

  const handleDocumentsPartArray = (documents) => {
    // console.log('handleDocumentsPartArray chamado com documentos:', documents)
    setDocumentsPartArray(documents)
  }

  const handleTagSelected = (tag) => {
    // console.log('nao ta batendo?', tag)
    setPartSelected(tag)
  }

  useEffect(() => {
    console.log('resumeTags', resumeTags)
  }, [resumeTags])

  const setDocumentColor = (idFileDoc, document, color) => {
    // console.log('setDocumentColor chamado com:', { idFileDoc, document, color })
    setColorsByDocumentId((prevColors) => ({
      ...prevColors,
      [idFileDoc]: {
        ...(prevColors[idFileDoc] || {}),
        [document]: color,
      },
    }))
  }

  const generateRandomColor = () => {
    // console.log('generateRandomColor chamado')
    const minColorValue = 0 // Valor mínimo para os componentes RGB
    const maxColorValue = 180 // Limita o máximo para evitar tons muito claros
    const randomColor = `rgb(${[0, 1, 2]
      .map(() =>
        Math.floor(
          Math.random() * (maxColorValue - minColorValue) + minColorValue
        )
      )
      .join(', ')})`
    // console.log('Cor gerada:', randomColor)
    return randomColor
  }

  const getDocumentColor = (idFileDoc, document) => {
    return colorsByDocumentId[idFileDoc]?.[document] || generateRandomColor()
  }

  const handleActiveTag = (item, numberTagShowForm) => {
    // console.log(`handleactive, item`, item)
    // console.log(`handleactive, item`, item, { numberTagShowForm })
    // console.log('qual alldatavalidated', allDataValidated)
    if (!showForm) {
      setActiveTag(item)
      // console.log('aqui ta dentro de activetag', item)
      setCollection(item?.documentId)
      setIdFileDoc(item?.documentId[0])
    } else {
      // console.log(`handleactive, item`, item, { numberTagShowForm })
      // console.log('qual alldatavalidated', allDataValidated)
      const updatedItem = {
        ...item,
        numberTagShowForm,
      }
      const getCollection = item.documentIdByNumberTag.find(
        (item) => item.numberTag === numberTagShowForm
      )
      setActiveTag(updatedItem)
      // console.log('aqui ta dentro de activetag', item)
      // console.log('getCollection dentro de handleActiveTag', getCollection)
      setCollection(getCollection?.documentsId)
      setIdFileDoc(getCollection?.documentsId[0])
    }
  }

  const handleUpdateActiveTag = (item) => {
    // console.log(`activetagupdate`, item)
    // console.log("quando bate aqui no handleUpdateActiveTag")
    setActiveTag(item)
  }

  useEffect(() => {
    // console.log(activeTag)
  }, [activeTag])

  const handleQttValidation = () => {
    // console.log('handleQttValidation chamado')
    const validatedCount = configData.documents.filter(
      (doc) => doc.validated === true
    ).length
    // console.log('Quantidade de documentos validados:', validatedCount)
    setTotalValidateDocs(validatedCount)
  }

  const handleSelectedDocument = (item) => {
    // console.log('handleSelectedDocument chamado com o item:', item)

    if (!item) {
      console.warn(
        'Aviso: O item fornecido para handleSelectedDocument está vazio ou indefinido.'
      )
    } else {
      // console.log('Atualizando estado de docSelected com o item:', item)
    }

    setDocSelected(item)
    // console.log('Estado de docSelected atualizado com sucesso.')
  }

  const handleOpenModalSend = () => {
    //  console.log('handleOpenModalSend chamado')
    setOpenModalSendDocs(!openModalSendDocs)
  }
  const handleErrorDocBox = (url) => {
    //  console.log('handleErrorDocBox chamado com URL:', url)
    const regex = /idDocBox=([^&]+)/
    const match = url.match(regex)

    if (match) {
      const idDocBox = match[1]
      //   console.log('ID Doc Box extraído:', idDocBox)

      setErrorDocBox((prevErrors) => {
        if (!prevErrors.includes(idDocBox)) {
          /* console.log(
            'Erro não encontrado anteriormente, adicionando:',
            idDocBox
          ) */
          return [...prevErrors, idDocBox]
        }
        return prevErrors
      })
    }
  }

  // const handleTypeDocumentsOption = async (value, id) => {
  //   let updatedDocument = null;
  //   document.body.style.cursor = "wait";
  //   setLoadingDocumentsData("loading");

  //   const updatedDocuments = configData.documents.map((doc) => {
  //     if (doc.documentId === id) {
  //       updatedDocument = {
  //         ...doc,
  //         classification: value,
  //       };
  //       return updatedDocument;
  //     }
  //     return doc;
  //   });

  //   console.log("updatedDocument", updatedDocument);

  //   let idAnalyzer = null;
  //   analyzerDataList?.forEach((analyzerDoc) => {
  //     if (analyzerDoc?.retorno.idDocBox === id) {
  //       idAnalyzer = analyzerDoc.retorno.idAnalyzer;
  //     }
  //   });

  //   const newAnalyzerDataListFiltered = analyzerDataList?.filter(
  //     (item) => item?.retorno.idDocBox !== id
  //   );

  //   setAnalyzerDataList(newAnalyzerDataListFiltered);

  //   const payload = {
  //     idParams: configId,
  //     classificationDocument: updatedDocument,
  //     idAnalyzer,
  //   };

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(payload),
  //   };

  //   try {
  //     const response = await fetchWithAuth(
  //       `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/classifyDocument`,
  //       requestOptions
  //     );
  //     const result = await response.json();
  //     console.log("result", result);

  //     if (result?.retorno?.dados && Object.keys(result.retorno.dados).length === 0) {
  //       console.log("retorno.dados é um objeto vazio:", result.retorno.dados);
  //       setLoadingDocumentsData("vazio");
  //       return;
  //     }

  //     if (!result?.retorno?.idAnalyzer) {
  //       let newAnalyzerDataListFiltered = [];
  //       analyzerDataList?.forEach((item) => {
  //         if (item?.retorno?.idAnalyzer === idAnalyzer) {
  //           if (item && item.retorno) {
  //             item.retorno.dados = null;
  //             item.retorno.lista = null;
  //           }
  //         }
  //         newAnalyzerDataListFiltered.push(item);
  //       });

  //       setAnalyzerDataList(newAnalyzerDataListFiltered);
  //     } else {
  //       let newAnalyzerDataListFiltered = analyzerDataList?.filter(
  //         (item) => item?.retorno?.idAnalyzer !== result?.retorno?.idAnalyzer
  //       );
  //       newAnalyzerDataListFiltered.push(result);
  //       setAnalyzerDataList(newAnalyzerDataListFiltered);
  //     }

  //     setConfigData((prevData) => ({
  //       ...prevData,
  //       documents: updatedDocuments,
  //     }));

  //     setTypeDocuments(value);
  //     setStartingLoading("finished");
  //   } catch (error) {
  //     console.error("Erro na classificação do documento:", error);
  //     setLoadingDocumentsData("error");
  //     toast.error(`Erro na classificação do documento`, {
  //       theme: "light",
  //     });
  //   } finally {
  //     if (startingLoading !== "vazio") {
  //       setStartingLoading("finished");
  //     }
  //     document.body.style.cursor = "default";
  //   }
  // };

  const handleTypeDocumentsOption = async (value, id) => {
    let updatedDocument = null
    document.body.style.cursor = 'wait'

    // Inicializa o estado de carregamento para o documento com id específico
    setLoadingDocumentsData((prev) => ({
      ...prev,
      [id]: 'loading', // Define o status de carregamento como 'loading' para o id atual
    }))

    // Atualiza os documentos com a nova classificação
    const updatedDocuments = configData.documents.map((doc) => {
      if (doc.documentId === id) {
        updatedDocument = {
          ...doc,
          classification: value,
        }
        return updatedDocument
      }
      return doc
    })

    let idAnalyzer = null
    analyzerDataList?.forEach((analyzerDoc) => {
      if (analyzerDoc?.retorno.idDocBox === id) {
        idAnalyzer = analyzerDoc?.retorno.idAnalyzer
      }
    })

    const newAnalyzerDataListFiltered = analyzerDataList?.filter(
      (item) => item.retorno.idDocBox !== id
    )

    setAnalyzerDataList(newAnalyzerDataListFiltered)

    const payload = {
      idParams: configId,
      classificationDocument: updatedDocument,
      idAnalyzer,
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/classifyDocument`,
        requestOptions
      )
      const result = await response.json()

      if (!result?.retorno?.idAnalyzer) {
        let newAnalyzerDataListFiltered = []
        analyzerDataList?.forEach((item) => {
          if (item?.retorno?.idAnalyzer === idAnalyzer) {
            if (item && item.retorno) {
              item.retorno.dados = null
              item.retorno.lista = null
            }
          }
          newAnalyzerDataListFiltered.push(item)
        })

        setAnalyzerDataList(newAnalyzerDataListFiltered)
      } else {
        let newAnalyzerDataListFiltered = analyzerDataList?.filter(
          (item) => item?.retorno?.idAnalyzer !== result?.retorno?.idAnalyzer
        )
        newAnalyzerDataListFiltered.push(result)
        setAnalyzerDataList(newAnalyzerDataListFiltered)
      }

      setConfigData((prevData) => ({
        ...prevData,
        documents: updatedDocuments,
      }))

      setTypeDocuments(value)
      // console.log("ta batendo aqui pelo menos?");

      // Atualiza o estado de loadingDocumentsData baseado nos dados recebidos
      if (
        result?.retorno?.dados &&
        Object.keys(result?.retorno?.dados).length === 0
      ) {
        // console.log('retorno.dados é vazio:', result?.retorno?.dados);
        setLoadingDocumentsData((prev) => ({
          ...prev,
          [id]: 'vazio', // Marca o status como 'vazio' se os dados forem vazios
        }))
      } else {
        // console.log('retorno.dados preenchido:', result.retorno.dados);
        setLoadingDocumentsData((prev) => ({
          ...prev,
          [id]: 'finished', // Marca o status como 'finished' se os dados foram preenchidos
        }))
      }
    } catch (error) {
      console.error('Erro na classificação do documento:', error)
      setLoadingDocumentsData((prev) => ({
        ...prev,
        [id]: 'error', // Marca o status como 'error' se ocorrer um erro
      }))
      toast.error(`Erro na classificação do documento`, {
        theme: 'light',
      })
    } finally {
      // Restaura o cursor ao estado padrão
      document.body.style.cursor = 'default'
    }
  }

  const handlePartForm = (tag) => {
    setPartFormListState(tag)
    // console.log(`partFormListState`, partFormListState)
  }

  // useEffect(() => {
  //   console.log(`USEEFFECT partFormListState:`, partFormListState)
  // }, [partFormListState])

  // useEffect(() => {
  //   console.log(`USEEFFECT activetag:`, activeTag)
  // }, [partFormListState])

  const handleClassificationDocs = (
    option,
    itemKeySelect,
    idSelected,
    value
  ) => {
    const findIndexMainKey = submitDocList.find(
      (item) => item.key === itemKeySelect
    )
    // console.log('Item encontrado para itemKeySelect:', findIndexMainKey)

    if (findIndexMainKey) {
      setSubmitDocList((prevList) => {
        // console.log('Atualizando submitDocList, lista anterior:', prevList)
        const updatedList = prevList.map((prev) => {
          if (prev.key === itemKeySelect) {
            const existingItemIndex = prev.item.findIndex(
              (item) => item.id === idSelected
            )

            if (existingItemIndex !== -1) {
              const updatedItems = prev.item.map((item, index) => {
                if (index === existingItemIndex) {
                  return {
                    ...item,
                    id: idSelected,
                    keyJson: option.key,
                    labelJson: option.label,
                    valueDoc: value,
                  }
                }
                return item
              })

              return {
                key: prev.key,
                item: updatedItems,
              }
            } else {
              return {
                key: prev.key,
                item: [
                  ...(Array.isArray(prev.item) ? prev.item : []),
                  {
                    id: idSelected,
                    keyJson: option.key,
                    labelJson: option.label,
                    valueDoc: value,
                  },
                ],
              }
            }
          }
          return prev
        })

        return updatedList
      })
    } else {
      const keyDocSubmit = {
        key: itemKeySelect,
        item: [
          {
            id: idSelected,
            keyJson: option.key,
            labelJson: option.label,
            valueDoc: value,
          },
        ],
      }
      //  console.log('Novo item criado para submitDocList:', keyDocSubmit)

      setSubmitDocList((prevList) => [...prevList, keyDocSubmit])
    }
  }

  useEffect(() => {
    //  console.log('submitDocList atualizado:', submitDocList)
  }, [submitDocList])

  const handleValidateDocument = () => {}

  useEffect(() => {
    const totalValidated = configData?.documents?.reduce((acc, currentItem) => {
      if (currentItem?.validated) {
        return acc + 1
      }
      return acc
    }, 0)

    setTotalValidateDocs(totalValidated)
    // console.log(totalValidated)
  }, [configData])

  const handleAllDocsValidatesValue = (value) => {
    value === true ? setAllDocsValidated(true) : setAllDocsValidated(false)
  }

  const handleContractParts = (part) => {
    //  console.log('handleContractParts chamado com:', part)
    if (contractPartsSelected.length >= 1) {
      const hasSameIdDocument = contractPartsSelected?.map((group) => {
        const filteredItems = group.items?.filter(
          (item) => item.idDocument !== part.idDocument
        )
        return { ...group, items: filteredItems }
      })

      setContractPartsSelected(hasSameIdDocument)
    }

    setContractPartsSelected((prev) => {
      const existingPart = prev.find((item) => item.name === part.name)
      if (existingPart) {
        const updatedParts = prev.map((item) =>
          item.name === part.name
            ? { ...item, items: [...item.items, part] }
            : item
        )
        return updatedParts
      }
      return [
        ...prev,
        { id: part.id, name: part.name, color: part.color, items: [part] },
      ]
    })
  }

  const handleAddFilter = (filter) => {}

  useEffect(() => {
    const filteredArray = contractPartsSelected.filter(
      (part) => part.items.length >= 1
    )
    if (filteredArray) {
      setFilteredPartsContract(filteredArray)
    } else {
    }
  }, [contractPartsSelected])

  const handleFilter = async (tag) => {
    if (!tag || !tag.documentId || tag.documentId.length <= 0) {
      handleClearFilter()
      setFilterselectedcolor()
      return
    }
    // console.log("por um acaso chamou aqui") //nao chama aqui
    // Verifica se 'tag' existe antes de continuar
    if (tag) {
      const updatedCollection = tag.documentId
      setCollection(updatedCollection)
      setFilterselectedcolor(tag)
      // console.log('talvez aqui?')
      setIdFileDoc(tag.documentId[0])
    } else {
      setFilterselectedcolor()
    }
  }

  const updatedFilterSelected = (tag) => {
    // console.log("batendooooooo")
    // console.log('updatedFilterSelected chamado com tag:', tag)

    let selectedTag

    if (filterSelectedColor?.part === 'Pendentes') {
      selectedTag = tag.find((item) => item.part === 'Pendentes')
      // console.log(
      //   'Filtro aplicado: Pendentes. selectedTag encontrado:',
      //   selectedTag
      // )
    } else if (filterSelectedColor?.part === 'Validados') {
      selectedTag = tag.find((item) => item.part === 'Validados')
      // console.log(
      //   'Filtro aplicado: Validados. selectedTag encontrado:',
      //   selectedTag
      // )
    } else if (filterSelectedColor?.part === 'Não Cadastrados') {
      selectedTag = tag.find((item) => item.part === 'Não Cadastrados')
      // console.log(
      //   'Filtro aplicado: Não Cadastrados. selectedTag encontrado:',
      //   selectedTag
      // )
    }

    if (selectedTag && selectedTag.documentId.length === 0) {
      console.warn('selectedTag encontrado, mas documentId está vazio.')
      handleClearFilter()
      return
    }

    if (selectedTag) {
      // console.log('selectedTag válido encontrado:', selectedTag)
      // setCollection()
      const updatedCollection = selectedTag?.documentId
      // console.log('Atualizando collection com:', updatedCollection)
      setCollection(updatedCollection)
      setFilterselectedcolor(selectedTag)
      // console.log('oi gente')
      setIdFileDoc(selectedTag?.documentId[0])
      // console.log('IdFileDoc atualizado para:', selectedTag?.documentId[0])
    } else {
      // console.log('Nenhum selectedTag encontrado. Limpando filtros.')
      setFilterselectedcolor()
      handleClearFilter()
    }
  }

  const handleClearFilter = () => {
    //  console.log('handleClearFilter chamado')
    const idCollectionInitial = configData?.documents.map(
      (item) => item.documentId
    )
    setCollection(idCollectionInitial)
    setFilterselectedcolor()
  }

  const handleAnalyzerData = (update) => {
    // console.log('Dados recebidos para atualizar em handleAnalyzerData:', update)

    if (!update || (Array.isArray(update) && update.length === 0)) {
      console.warn(
        'Aviso: Os dados fornecidos para atualização estão vazios ou indefinidos.'
      )
    }

    setAnalyzerDataList(update)
    // console.log('Estado de analyzerDataList atualizado com sucesso.')
  }

  const handleConfigData = (updatedDocuments) => {
    // console.log(
    //   'handleConfigData chamado com updatedDocuments:',
    //   updatedDocuments
    // )

    if (
      !updatedDocuments ||
      (Array.isArray(updatedDocuments) && updatedDocuments.length === 0)
    ) {
      console.warn(
        'Aviso: Os documentos fornecidos para atualização estão vazios ou indefinidos.'
      )
    }

    setConfigData((prevConfigData) => {
      // console.log('Estado anterior de configData:', prevConfigData)
      const newConfigData = {
        ...prevConfigData,
        documents: updatedDocuments,
      }
      // console.log('Novo estado de configData após atualização:', newConfigData)
      return newConfigData
    })

    // console.log('Estado de configData atualizado com sucesso.')
  }

  const sendNewDocument = async (document) => {
    // console.log(
    //   'Iniciando envio de novo documento com o seguinte payload:',
    //   document
    // )

    if (!document || typeof document !== 'object') {
      console.warn('Aviso: O documento fornecido é inválido ou está vazio.')
      return null // Ou você pode tratar com outra lógica de fallback ou erro
    }

    const payload = {
      idParams: configId,
      document: document,
    }

    // console.log('Payload que será enviado na requisição:', payload)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    // console.log('Opções da requisição:', requestOptions)

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/validateDocument`,
        requestOptions
      )

      // console.log('Resposta da requisição recebida:', response)
      if (!response.ok) {
        console.error(
          'Erro na resposta da requisição. Status:',
          response.status,
          'Status Text:',
          response.statusText
        )
      }
      return response
    } catch (error) {
      console.error('Erro ao tentar enviar o documento:', error)
      throw error // Repassa o erro para quem chama a função
    }
  }

  const handleOpenModalAllLists = () => {
    setOpenModalAllLists(!openModalAllLists)
  }

  const handlePreviewDocsReview = () => {
    setOpenDocumentPreview(!openDocumentPreview)
  }

  //retorna os ids na configData que tem na collection atual
  const filterDocumentsByCollection = (configData, collection) => {
    return configData?.documents?.filter((item) =>
      collection.includes(item.documentId)
    )
  }

  //exclui um documento pelo documentId
  const deleteDocumentFromConfig = (configData, idFileDoc) => {
    return configData?.documents?.filter((doc) => doc.documentId !== idFileDoc)
  }

  //atualiza a lista de documentos do analyzer
  const updateDocumentListData = (documentListData, idFileDoc) => {
    return documentListData?.filter((doc) => idFileDoc !== doc.documentId)
  }

  // atualiza a collection removendo o documentId
  // const updateCollection = (collection, idFileDoc) => {
  //   return collection.filter((doc) => idFileDoc !== doc)
  // }

  //calcula o próximo idFileDoc peloo índice(nao funciona)
  // const calculateNextIdFileDoc = (newCollection, nextIndex) => {
  //   if (nextIndex < newCollection.length) {
  //     return newCollection[nextIndex]
  //   } else if (nextIndex - 1 >= 0) {
  //     return newCollection[nextIndex - 1]
  //   }
  //   return null
  // }

  // const handleCollection = async () => {
  //   const configDataFilter = filterDocumentsByCollection(configData, collection)

  //   if (configDataFilter) {
  //     // const documentToDeleteIndex = configDataFilter?.findIndex(
  //     //   (item) => item.documentId === idFileDoc
  //     // )

  //     const documentToDelete = configData?.documents.find(
  //       (item) => item.documentId === idFileDoc
  //     )

  //     // const newCollection = updateCollection(collection, idFileDoc)

  //     const payload = {
  //       idParams: configId,
  //       document: documentToDelete,
  //     }

  //     const requestOptions = {
  //       method: 'DELETE',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(payload),
  //     }

  //     document.body.style.cursor = 'wait'

  //     try {
  //       const response = await fetchWithAuth(
  //         `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/removeDocument`,
  //         requestOptions
  //       )

  //       if (!response.ok) {
  //         throw new Error('Erro ao tentar remover o documento no servidor.')
  //       }

  //       const updatedDocuments = deleteDocumentFromConfig(configData, idFileDoc)

  //       setConfigData((prevConfigData) => ({
  //         ...prevConfigData,
  //         documents: updatedDocuments,
  //       }))

  //       const updatedDocumentListData = updateDocumentListData(
  //         documentListData,
  //         idFileDoc
  //       )
  //       setDocumentListData(updatedDocumentListData)

  //       // setCollection(newCollection)
  //       // if (newCollection.length > 0) {
  //       //   // console.log('mds quanto lugar')
  //       //   setIdFileDoc(newCollection[0])
  //       // } else {
  //       //   const ids = updatedDocuments?.map((item) => item.documentId)
  //       //   setCollection(ids)
  //       //   // console.log('pode ser aqui?')
  //       //   setIdFileDoc(ids[0] || null)
  //       //   setFilterselectedcolor()
  //       // }

  //       toast.success(`Documento removido com sucesso!`, {
  //         theme: 'light',
  //       })
  //     } catch (error) {
  //       console.error('Erro na requisição:', error)
  //       return toast.error(`Erro ao deletar o documento, tente novamente!`, {
  //         theme: 'light',
  //       })
  //     } finally {
  //       document.body.style.cursor = 'default'
  //     }
  //   }
  // }

  const handleCollection = async () => {
    // console.log('Iniciando handleCollection')
    console.log('ConfigData:', configData)
    console.log('Collection:', collection)
    console.log('filterselectedcolor:', filterSelectedColor)

    const configDataFilter = filterDocumentsByCollection(configData, collection)
    console.log('ConfigDataFilter:', configDataFilter)

    if (configDataFilter) {
      console.log('ID do documento a ser removido:', idFileDoc)

      if (!configData || !configData.documents) {
        console.error('ConfigData ou configData.documents está indefinido.')
        return toast.error('Erro ao processar os dados, tente novamente!', {
          theme: 'light',
        })
      }

      const documentToDelete = configData.documents.find((item) => {
        console.log('documentToDelete: Verificando item:', item)
        return item.documentId === idFileDoc
      })

      if (!documentToDelete) {
        console.error('Documento não encontrado com o ID:', idFileDoc)
        return toast.error('Documento não encontrado!', {
          theme: 'light',
        })
      }

      console.log('Documento a ser removido:', documentToDelete)

      const payload = {
        idParams: configId,
        document: documentToDelete,
      }

      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      }

      document.body.style.cursor = 'wait'

      try {
        const response = await fetchWithAuth(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/removeDocument`,
          requestOptions
        )

        if (!response.ok) {
          throw new Error('Erro ao tentar remover o documento no servidor.')
        }

        console.log('Requisição bem-sucedida:', response)

        const updatedDocuments = deleteDocumentFromConfig(configData, idFileDoc)
        console.log('Documentos atualizados:', updatedDocuments)

        setConfigData((prevConfigData) => ({
          ...prevConfigData,
          documents: updatedDocuments,
        }))

        const updatedDocumentListData = updateDocumentListData(
          documentListData,
          idFileDoc
        )
        console.log('Lista de documentos atualizada:', updatedDocumentListData)
        setDocumentListData(updatedDocumentListData)

        const updatedCollectionTest = updatedDocuments.filter(
          (doc) => doc.validated === documentToDelete.part
        )

        console.log('updatedCollectionTest', updatedCollectionTest)

        if (documentToDelete) {
          if (filterSelectedColor) {
            filterSelectedColor.documentId =
              filterSelectedColor.documentId.filter(
                (id) => id !== documentToDelete.documentId
              )

            setCollection(filterSelectedColor.documentId)
            setIdFileDoc(filterSelectedColor.documentId[0])

            console.log(
              filterSelectedColor,
              filterSelectedColor.documentId.length
            )

            if (filterSelectedColor.documentId.length === 0) {
              handleClearFilter()
              const updatedCollection = updatedDocuments.map(
                (doc) => doc.documentId
              )
              setCollection(updatedCollection)
              setIdFileDoc(updatedCollection[0])
            }
          } else {
            const updatedCollection = updatedDocuments.map(
              (doc) => doc.documentId
            )
            setCollection(updatedCollection)
            setIdFileDoc(updatedCollection[0])
            console.log('documento resolvido')
          }
          toast.success(`Documento removido com sucesso!`, {
            theme: 'light',
          })
        }
      } catch (error) {
        console.error('Erro na requisição:', error)
        return toast.error(`Erro ao deletar o documento, tente novamente!`, {
          theme: 'light',
        })
      } finally {
        document.body.style.cursor = 'default'
      }
    }
  }

  // const handleClassification = (docValidatedData) => {
  //   try {
  //     console.log(
  //       'handleClassification chamado com docValidatedData:',
  //       docValidatedData
  //     )

  //     let localCount = 0

  //     const newDocListData = configData?.documents?.map((doc) => {
  //       if (doc.documentId === docValidatedData.documentId) {
  //         // console.log(`Documento encontrado com ID correspondente:`, doc)
  //         return docValidatedData
  //       } else {
  //         return doc
  //       }
  //     })

  //     const analyzerDataValidatedDocument = analyzerDataList.find(
  //       (data) => data?.retorno?.idDocBox === docValidatedData?.documentId
  //     )
  //     // console.log(
  //     //   'Dados de analyzerDataValidatedDocument encontrados:',
  //     //   'analyzerDataValidatedDocument declarado:',
  //     //   analyzerDataValidatedDocument
  //     // )

  //     newDocListData.forEach((document, index) => {
  //       try {
  //         // console.log(`Processando documento na iteração ${index}:`, document)

  //         const analyzerData = analyzerDataList.find(
  //           (data) => data?.retorno?.idDocBox === document?.documentId
  //         )

  //         if (!document.validated && !document.part) {
  //           // console.log('Documento sem validação e parte definida:', document)

  //           if (analyzerData) {
  //             const docValidated = docValidatedData

  //             if (docValidated?.classification?.titulo) {
  //               const tituloKey = docValidated.classification.titulo
  //               const tituloValue =
  //                 analyzerDataValidatedDocument?.retorno?.dados[
  //                   tituloKey
  //                 ]?.toLowerCase() || null
  //               const ocrContent =
  //                 analyzerData?.retorno?.metaDados?.ocrContent?.toLowerCase() ||
  //                 ''

  //               // console.log('Título extraído:', tituloValue)
  //               // console.log('Conteúdo OCR:', ocrContent)

  //               if (tituloValue && ocrContent) {
  //                 const tituloValueNormalized = tituloValue.toLowerCase().trim()
  //                 const ocrContentNormalized = ocrContent.toLowerCase().trim()

  //                 const removeSpecialCharacters = (str) =>
  //                   str.replace(/[^\w\s]/gi, '')

  //                 const tituloValueClean = removeSpecialCharacters(
  //                   tituloValueNormalized
  //                 )
  //                 const ocrContentClean =
  //                   removeSpecialCharacters(ocrContentNormalized)

  //                 const allWordsMatch =
  //                   ocrContentClean.includes(tituloValueClean)

  //                 if (allWordsMatch) {
  //                   // console.log('Correspondência encontrada no conteúdo OCR.')

  //                   const updatedDocument = {
  //                     ...document,
  //                     part: docValidatedData?.part || null,
  //                     numberTag: docValidatedData?.numberTag || null,
  //                   }

  //                   Object.assign(updatedDocument, { suggested: true })

  //                   // Incrementa a variável local
  //                   localCount += 1

  //                   // console.log(
  //                   //   'Object.assign funcionou?',
  //                   //   updatedDocument?.suggested
  //                   // )

  //                   // console.log('Saída de updatedDocument', updatedDocument)
  //                   // const array = (newDocListData[index] = updatedDocument)
  //                   // console.log('Saída do Array de newDocListData', array)
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       } catch (error) {
  //         console.error(`Erro no loop na iteração ${index}:`, error)
  //       }
  //     })

  //     if (localCount > 0) {
  //       const message =
  //         localCount === 1
  //           ? `Identificamos e classificamos 1 documento que parece estar relacionado à mesma parte!`
  //           : `Identificamos e classificamos ${localCount} documentos que parecem estar relacionados à mesma parte!`

  //       toast.info(message, { theme: 'light' })
  //       try {
  //         appInsights &&
  //           appInsights.trackEvent({
  //             name: 'Documentos associados com sucesso',
  //             properties: {
  //               docValidatedId: docValidatedData?.documentId,
  //               docValidatedPart: docValidatedData?.part,
  //               associatedDocs: localCount,
  //             },
  //           })
  //         // console.log('Evento de rastreamento registrado com sucesso.')
  //       } catch (error) {
  //         console.error('Erro ao rastrear evento com appInsights:', error)
  //         appInsights && appInsights.trackException({ error: new Error(error) })
  //       }
  //     }

  //     // console.log('Atualizando newDocListData com:', newDocListData)
  //     setDocumentListData(newDocListData)
  //     handleConfigData(newDocListData)
  //   } catch (error) {
  //     console.error('Erro em handleClassification:', error)
  //   }
  // }

  const handleClassification = (docValidatedData) => {
    try {
      let localCount = 0 // Variável local para contar os documentos

      const newDocListData = configData?.documents?.map((doc) => {
        if (doc.documentId === docValidatedData.documentId) {
          return docValidatedData
        } else {
          return doc
        }
      })

      // console.log('newDocListData declarado:', newDocListData)
      const analyzerDataValidatedDocument = analyzerDataList.find(
        (data) => data?.retorno?.idDocBox === docValidatedData?.documentId
      )
      // console.log(
      //   'analyzerDataValidatedDocument declarado:',
      //   analyzerDataValidatedDocument
      // )

      newDocListData.forEach((document, index) => {
        try {
          // console.log(`Passei no loop ${index} vezes`)
          const analyzerData = analyzerDataList.find(
            (data) => data?.retorno?.idDocBox === document?.documentId
          )
          // console.log('analyzerData declarado:', analyzerData)
          if (!document.validated && !document.part) {
            if (analyzerData) {
              const docValidated = docValidatedData

              if (docValidated?.classification?.titulo) {
                // console.log('docValidated:', docValidated)
                const tituloKey = docValidated.classification.titulo
                const tituloValue =
                  analyzerDataValidatedDocument?.retorno?.dados[
                    tituloKey
                  ]?.toLowerCase() || null
                const ocrContent =
                  analyzerData?.retorno?.metaDados?.ocrContent?.toLowerCase() ||
                  ''

                if (tituloValue && ocrContent) {
                  const tituloValueNormalized = tituloValue.toLowerCase().trim()
                  const ocrContentNormalized = ocrContent.toLowerCase().trim()

                  const removeSpecialCharacters = (str) =>
                    str.replace(/[^\w\s]/gi, '')

                  const tituloValueClean = removeSpecialCharacters(
                    tituloValueNormalized
                  )
                  const ocrContentClean =
                    removeSpecialCharacters(ocrContentNormalized)

                  const allWordsMatch =
                    ocrContentClean.includes(tituloValueClean)

                  if (allWordsMatch) {
                    const updatedDocument = {
                      ...document,
                      part: docValidatedData?.part || null,
                      numberTag: docValidatedData?.numberTag || null,
                    }

                    Object.assign(updatedDocument, { suggested: true })

                    // Incrementa a variável local
                    localCount += 1

                    // console.log(
                    //   'Object.assign funcionou?',
                    //   updatedDocument?.suggested
                    // )

                    // console.log('Saída de updatedDocument', updatedDocument)
                    const array = (newDocListData[index] = updatedDocument)
                    // console.log('Saída do Array de newDocListData', array)
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error(`Erro no loop na iteração ${index}:`, error)
        }
      })

      // Usa localCount para verificar se deve exibir o toast
      if (localCount > 0) {
        const message =
          localCount === 1
            ? `Identificamos e classificamos 1 documento que parece estar relacionado à mesma parte!`
            : `Identificamos e classificamos ${localCount} documentos que parecem estar relacionados à mesma parte!`

        toast.info(message, { theme: 'light' })
      }
      // console.log("localCount", localCount)
      // console.log('Saída de newDocListData', newDocListData)
      setDocumentListData(newDocListData)
      handleConfigData(newDocListData)
      // console.log('Saída de configData', configData)
    } catch (error) {
      console.error('Erro em handleClassification:', error)
    }
  }

  const handleDataDocumentToLint = (data) => {
    // console.log('quem é data', data)
    setDataDocumentToLint((prev) => {
      const hasData = prev.findIndex(
        (item) => item.part === data.part && item.numberTag === data.numberTag
      )

      return hasData !== -1
        ? prev.map((item, index) => (index === hasData ? data : item))
        : [...prev, data]
    })
  }

  const updatedDataDocumentToLint = (name, value) => {
    setDataDocumentToLint((prevData) => {
      return prevData.map((item) => {
        if (
          item.part === activeTag.part &&
          item.numberTag === activeTag.numberTagShowForm
        ) {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          }
        }
        return item
      })
    })
  }

  // const handlePartFormUpdate = async (tag) => {
  //   // console.log(`Tag recebida para atualização:`, tag)
  //   // console.log(`Estado inicial de partFormListState:`, partFormListState)

  //   if (!tag) {
  //     console.error('Erro: tag não fornecida ou inválida.')
  //     return
  //   }

  //   if (isFormDataEmpty) {
  //     console.warn(
  //       `Formulário está vazio. Mensagem de erro exibida ao usuário.`
  //     )
  //     return toast.error(
  //       `Por favor, revise e preencha todos os campos antes de validar.`,
  //       { theme: 'light' }
  //     )
  //   }

  //   const updatedForm = partFormListState?.map((item) => {
  //     // console.log("Verificando item:", item); //aqui ja esta atualizado o true ou false
  //     // console.log("tag?.numberTagShowForm:", tag?.numberTagShowForm);

  //     if (item?.part === tag.part) {
  //       // console.log(`Item correspondente encontrado para a parte:`, item.part)

  //       const updatedTagsValidated = item.tagsValidated.map((tagItem) => {
  //         // console.log("Verificando tagItem antes da atualização:", tagItem);

  //         const updatedTagItem =
  //           tagItem.numberTag === tag?.numberTagShowForm
  //             ? { ...tagItem, tagValidated: !tagItem.tagValidated }
  //             : tagItem

  //         // console.log("tagItem após verificação:", updatedTagItem);
  //         return updatedTagItem
  //       })

  //       const updatedItem = {
  //         ...item,
  //         tagsValidated: updatedTagsValidated,
  //         numberTagShowForm: tag?.numberTagShowForm,
  //       }

  //       // console.log("item atualizado com tagsValidated:", updatedItem);
  //       return updatedItem
  //     }

  //     // console.log("item sem alterações:", item);
  //     return item
  //   })

  //   // console.log('Resultado final de updatedForm:', updatedForm)
  //   const updatedActiveTag = updatedForm?.find(
  //     (item) =>
  //       item.part === tag.part &&
  //       item.numberTagShowForm === tag?.numberTagShowForm
  //   )

  //   // console.log('ActiveTag atualizado encontrado:', updatedActiveTag)

  //   handleAllDataValidated(updatedActiveTag)
  //   // console.log('Estado atualizado de activeTag antes da mudança:', activeTag)
  //   // console.log('Estado atualizado de activeTag:', updatedActiveTag)

  //   setActiveTag(updatedActiveTag)
  //   setPartFormListState(updatedForm)

  //   try {
  //     appInsights &&
  //       appInsights.trackEvent({
  //         name: 'Formulário validado com sucesso',
  //         properties: {
  //           updatedForm,
  //         },
  //       })
  //     // console.log('Evento de rastreamento do formulário bem-sucedido.')
  //   } catch (error) {
  //     console.error('Erro ao tentar rastrear evento com appInsights:', error)
  //     appInsights && appInsights.trackException({ error: new Error(error) })
  //   }
  //   const itensForTag = resumeTags?.find(
  //     (item) => item.tag === updatedActiveTag.part
  //   )
  //   if (itensForTag) {
  //     itensForTag.tagsValidated = updatedActiveTag?.tagsValidated || []
  //   }

  //   dataDocumentToLint.forEach((document) => {
  //     const { numberTag, data } = document

  //     Object.entries(data).forEach(([key, value]) => {
  //       const item = itensForTag?.itens?.find((item) => item.key === key)

  //       if (item) {
  //         if (!item.values) {
  //           item.values = []
  //         }

  //         const existingData = item.values.find(
  //           (data) => data.numberTag == numberTag
  //         )

  //         if (existingData) {
  //           existingData.value = value
  //         } else {
  //           item.values.push({ numberTag, value })
  //         }
  //       }
  //     })
  //   })

  //   // console.log('essa é a tag que recebe', tag)
  //   // console.log('updatedForm', updatedForm)
  //   // console.log('updatedActiveTag', updatedActiveTag)
  //   // console.log('resumeTags', resumeTags)
  //   // console.log('dataDocumentToLint', dataDocumentToLint)
  //   // console.log('itensForTag', itensForTag)

  //   const payload = {
  //     idParams: configId,
  //     tagName: itensForTag.tag,
  //     newData: itensForTag,
  //   }

  //   // console.log('Payload que será enviado na requisição:', payload)

  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(payload),
  //   }

  //   // console.log('Opções da requisição:', requestOptions)

  //   try {
  //     const response = await fetchWithAuth(
  //       `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/setDataTags`,
  //       requestOptions
  //     )

  //     // console.log('Resposta da requisição recebida:', response)
  //     if (!response.ok) {
  //       console.error(
  //         'Erro na resposta da requisição. Status:',
  //         response.status,
  //         'Status Text:',
  //         response.statusText
  //       )
  //       toast.error(
  //         `Os dados não foram validados, tente novamente!`,
  //         {
  //           theme: 'light',
  //         }
  //       )
  //     } else {
  //       // toast.success(`Dados validados com sucesso!`, {
  //       //   theme: 'light',
  //       //   progressStyle: { backgroundColor: '#9ca624' },
  //       //   style: {
  //       //     background: '#f4f4f4',
  //       //   },
  //       // })
  //       //ajusta para quando for validado e invalidado
  //     }

  //     return response
  //   } catch (error) {
  //     console.error('Erro ao tentar enviar o documento:', error)
  //     toast.error(`Erro ao validar os dados. Tente novamente.`, {
  //       theme: 'light',
  //     })
  //     throw error
  //   }
  // }

  const handlePartFormUpdate = async (tag) => {
    if (!tag) {
      console.error('Erro: tag não fornecida ou inválida.')
      return
    }

    if (isFormDataEmpty) {
      toast.error(
        'Por favor, revise e preencha todos os campos antes de validar.',
        { theme: 'light' }
      )
      return
    }

    const updatedForm = partFormListState?.map((item) =>
      item?.part === tag.part
        ? {
            ...item,
            tagsValidated: item.tagsValidated.map((tagItem) =>
              tagItem.numberTag === tag?.numberTagShowForm
                ? { ...tagItem, tagValidated: !tagItem.tagValidated }
                : tagItem
            ),
            numberTagShowForm: tag?.numberTagShowForm,
          }
        : item
    )

    const updatedActiveTag = updatedForm?.find(
      (item) =>
        item.part === tag.part &&
        item.numberTagShowForm === tag?.numberTagShowForm
    )

    const itensForTag = resumeTags?.find(
      (item) => item.tag === updatedActiveTag?.part
    )
    if (itensForTag) {
      itensForTag.tagsValidated = updatedActiveTag?.tagsValidated || []
    }

    dataDocumentToLint.forEach(({ numberTag, data }) => {
      Object.entries(data).forEach(([key, value]) => {
        const item = itensForTag?.itens?.find((i) => i.key === key)
        if (item) {
          item.values = item.values || []
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const existingData = item.values.find(
            (v) => Number(v.numberTag) === Number(numberTag)
          )
          existingData
            ? (existingData.value = value)
            : item.values.push({ numberTag, value })
        }
      })
    })

    // console.log("itens for tag", itensForTag)
    const payload = {
      idParams: configId,
      tagName: itensForTag?.tag,
      newData: itensForTag,
    }

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/setDataTags`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        console.error('Erro na resposta:', response.status, response.statusText)
        toast.error('Os dados não foram validados, tente novamente!', {
          theme: 'light',
        })
        return
      }

      const isValidated = updatedActiveTag?.tagsValidated.some(
        (tag) => tag.tagValidated
      )
      toast[isValidated ? 'success' : 'info'](
        isValidated ? 'Dados validados com sucesso!' : 'Validação removida.',
        {
          theme: 'light',
          progressStyle: isValidated
            ? { backgroundColor: '#9ca624' }
            : undefined,
          style: { background: '#f4f4f4' },
        }
      )

      handleAllDataValidated(updatedActiveTag)
      setActiveTag(updatedActiveTag)
      setPartFormListState(updatedForm)
    } catch (error) {
      console.error('Erro ao enviar o documento:', error)
      toast.error('Erro ao validar os dados. Tente novamente.', {
        theme: 'light',
      })
    }
  }

  useEffect(() => {
    // console.log('dataDocumentToLint', dataDocumentToLint)
    // console.log('resumeTags', resumeTags)
  }, [dataDocumentToLint])

  const handleAllDataValidated = (data) => {
    if (dataDocumentToLint) {
      const isMatchingData =
        dataDocumentToLint?.part === data?.part &&
        dataDocumentToLint?.numberTag === data?.numberTagShowForm

      setAllDataValidated((prevData) => {
        const hasTag = prevData.some(
          (item) =>
            item.part === data.part && item.numberTag === data.numberTagShowForm
        )

        return hasTag
          ? prevData.map((item) =>
              item.part === data.part &&
              item.numberTag === data.numberTagShowForm
                ? {
                    ...item,
                    tagsValidated: item.tagsValidated.map((tagItem) =>
                      tagItem.numberTag === data.numberTagShowForm
                        ? { ...tagItem, tagValidated: !tagItem.tagValidated }
                        : tagItem
                    ),
                    dataIa: isMatchingData
                      ? dataDocumentToLint.data
                      : item.dataIa,
                  }
                : item
            )
          : [
              ...prevData,
              {
                ...data,
                tagsValidated: [
                  ...(data?.tagsValidated || []),
                  {
                    numberTag: data?.numberTagShowForm,
                    tagValidated: true,
                  },
                ],
                dataIa: isMatchingData ? dataDocumentToLint?.data : null,
              },
            ]
      })
    }
  }

  // useEffect(() => {
  //   console.log('allDataValidated', allDataValidated)
  //   console.log('dataDocumentToLint', dataDocumentToLint)
  // }, [allDataValidated])

  const handleCopyToClipboard = (formData) => {
    // console.log({ formData })
    if (!formData.data || Object.keys(formData.data).length === 0) {
      return toast.error(`Por favor, preencha os dados antes de continuar!`, {
        theme: 'light',
        progressStyle: { backgroundColor: '#a6151c' },
        style: {
          background: '#f4f4f4',
        },
      })
    }

    const formFields = resumeTags?.find((item) => item.tag === activeTag?.part)

    // console.log('formFields', formFields)

    const formDataText = formFields?.itens
      ?.map((doc) => `${doc.label}: ${formData.data[doc.key] || ''}`)
      .join('\n')

    // console.log('transferencia:', formDataText)

    navigator.clipboard
      .writeText(formDataText)
      .then(() =>
        toast.success(`Dados copiados para a área de transferência!`, {
          theme: 'light',
          progressStyle: { backgroundColor: '#9ca624' },
          style: {
            background: '#f4f4f4',
          },
        })
      )
      .catch((err) =>
        console.error('Erro ao copiar para a área de transferência: ', err)
      )
  }

  const handleCheckFormEmpty = (checkFormEmpty) => {
    setIsFormDataEmpty(checkFormEmpty)
  }

  useEffect(() => {
    // console.log('allDataValidated', allDataValidated)
    // console.log('quando muda o dataDocumentToLint', dataDocumentToLint)
  }, [dataDocumentToLint])

  const handleLoadingForm = (value) => {
    // console.log('ta batendo aqui?', value)
    setIsLoadingForm(value)
  }

  // useEffect(() => {
  //   if (configData?.documents && showForm) {
  //     setIsLoadingForm(true)
  //     const finalDocumentsArray = groupDocuments(
  //       configData?.documents,
  //       tagsWithColors
  //     )

  //     const groupedDocuments = finalDocumentsArray.reduce((acc, doc) => {
  //       const key = `${doc.part}-${doc.numberTag}`
  //       if (!acc[key]) {
  //         acc[key] = {
  //           id: Object.keys(acc)?.length + 1,
  //           part: doc.part,
  //           numberTag: doc.numberTag,
  //           documents: [],
  //           tagAdd: doc.tagAdd,
  //         }
  //       }

  //       doc.documentId?.forEach((id) => {
  //         const docConfigData = configData.documents?.find(
  //           (item) => item.documentId === id
  //         )
  //         const docAnalyzerData = analyzerDataList?.find(
  //           (item) => item?.retorno && item.retorno.idDocBox === id
  //         )

  //         if (docConfigData || docAnalyzerData) {
  //           acc[key].documents.push({
  //             documentId: id,
  //             documentData: {
  //               configData: docConfigData || {},
  //               analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //             },
  //           })
  //         }
  //       })

  //       return acc
  //     }, {})

  //     const otherDocuments = configData?.documents?.reduce((acc, doc) => {
  //       // console.log('chega a bater aqui?')
  //       //   console.log('entra aqui?')
  //       if (analyzerDataList) {
  //         const docAnalyzerData = analyzerDataList?.find(
  //           (item) => item?.retorno && item.retorno.idDocBox === doc.documentId
  //         )
  //         // console.log({ docAnalyzerData })
  //         acc.push({
  //           documentId: doc.documentId,
  //           documentData: {
  //             configData: doc || {},
  //             analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //           },
  //         })
  //       }

  //       return acc
  //     }, [])

  //     if (otherDocuments?.length > 0) {
  //       groupedDocuments['Outros'] = {
  //         id: Object.keys(groupedDocuments)?.length + 1,
  //         part: 'Outros',
  //         numberTag: null,
  //         documents: otherDocuments,
  //       }
  //     }

  //     const finalArray = Object.values(groupedDocuments)

  //     if (finalArray.length > 0) {
  //       const newCombinededDocuments = finalArray.map((doc) => {
  //         const dadosList =
  //           doc.documents?.flatMap((item) => {
  //             const dados = item?.documentData?.analyzerData?.dados || {}
  //             const label =
  //               item?.documentData?.configData?.classification?.label

  //             return Object.entries(dados).map(([key, value]) => ({
  //               key,
  //               label: label ? label : 'Não classificado',
  //               value: value ? value : 'Não cadastrado',
  //             }))
  //           }) || []

  //         const dadosSeparados = dadosList
  //           .filter(({ key }) => !/hidden/i.test(key))
  //           .map(({ key, value, label }) => ({
  //             key,
  //             label,
  //             value,
  //           }))

  //         return {
  //           part: doc?.part,
  //           numberTag: doc?.numberTag,
  //           dados: dadosSeparados,
  //         }
  //       })

  //       console.log(
  //         'quem tem dentro de newCombinededDocuments',
  //         newCombinededDocuments
  //       )

  //       if (newCombinededDocuments.length > 0) {
  //         setCombinededDocuments(newCombinededDocuments)
  //         if (newCombinededDocuments.length > 0) {
  //           setTimeout(() => {
  //             setIsLoadingForm(false)
  //           }, 3000)
  //         }
  //         console.log({ finalArray })
  //       }
  //     }

  //     // const processDocuments = (configData, tagsWithColors) => {
  //     //   if (configData?.documents) {
  //     //     // Agrupar documentos
  //     //     const groupedDocuments = configData.documents.reduce((acc, doc) => {
  //     //       const key = `${doc.part}-${doc.numberTag}`

  //     //       if (!acc[key]) {
  //     //         acc[key] = {
  //     //           id: Object.keys(acc).length + 1,
  //     //           part: doc.part,
  //     //           numberTag: doc.numberTag,
  //     //           documents: [],
  //     //           tagAdd: doc.tagAdd,
  //     //         }
  //     //       }

  //     //       doc.documentId?.forEach((id) => {
  //     //         const docConfigData = configData.documents.find(
  //     //           (item) => item.documentId === id
  //     //         )
  //     //         const docAnalyzerData = configData.documents.find(
  //     //           (item) => item?.retorno && item.retorno.idDocBox === id
  //     //         )

  //     //         if (docConfigData || docAnalyzerData) {
  //     //           acc[key].documents.push({
  //     //             documentId: id,
  //     //             documentData: {
  //     //               configData: docConfigData || {},
  //     //               analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //     //             },
  //     //           })
  //     //         }
  //     //       })

  //     //       return acc
  //     //     }, {})

  //     //     // Obter documentos "Outros"
  //     //     const otherDocuments = configData.documents.reduce((acc, doc) => {
  //     //       if (!doc.part && !doc.numberTag) {
  //     //         const docAnalyzerData = configData.documents.find(
  //     //           (item) =>
  //     //             item?.retorno && item.retorno.idDocBox === doc.documentId
  //     //         )
  //     //         acc.push({
  //     //           documentId: doc.documentId,
  //     //           documentData: {
  //     //             configData: doc || {},
  //     //             analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //     //           },
  //     //         })
  //     //       }
  //     //       return acc
  //     //     }, [])

  //     //     const finalArray = Object.values(groupedDocuments)

  //     //     if (otherDocuments.length > 0) {
  //     //       finalArray.push({
  //     //         id: Object.keys(groupedDocuments).length + 1,
  //     //         part: 'Outros',
  //     //         numberTag: null,
  //     //         documents: otherDocuments,
  //     //       })
  //     //     }

  //     //     // Combinar dados dos documentos
  //     //     const newCombinedDocuments = finalArray.map((doc) => {
  //     //       const dadosList =
  //     //         doc.documents?.flatMap((item) => {
  //     //           const dados = item?.documentData?.analyzerData?.dados || {}
  //     //           const label =
  //     //             item?.documentData?.configData?.classification?.label ||
  //     //             'Não classificado'

  //     //           return Object.entries(dados).map(([key, value]) => ({
  //     //             key,
  //     //             label,
  //     //             value: value || 'Não cadastrado',
  //     //           }))
  //     //         }) || []

  //     //       const dadosSeparados = dadosList
  //     //         .filter(({ key }) => !/hidden/i.test(key))
  //     //         .map(({ key, value, label }) => ({ key, label, value }))

  //     //       return {
  //     //         part: doc.part,
  //     //         numberTag: doc.numberTag,
  //     //         dados: dadosSeparados,
  //     //       }
  //     //     })

  //     //     // Atualiza o estado apenas se necessário

  //     //     setCombinededDocuments(newCombinedDocuments)
  //     //     handleLoadingForm(false)
  //     //   }
  //     // }
  //   }

  //   // Chame esta função passando configData e tagsWithColors
  //   // Exemplo: processDocuments(configData, tagsWithColors);
  // }, [configData, tagsWithColors]) // Adicione aqui as dependências necessárias

  /***** IA */

  const handleFormDataToAi = async () => {
    if (
      activeTag?.tagsValidated?.some(
        (tag) => tag.numberTag === activeTag.numberTag && tag.tagValidated
      )
    )
      return

    if (isLoadingForm) return
    setIsLoadingForm(true)

    const listField = resumeTags.find((tag) => tag.tag === activeTag.part)
    const templateField = listField?.itens
      .filter((item) => item.key && item.description)
      .map((item) => `'${item.key}' ${item.description}`)
      .join(', ')

    // console.log({ templateField })

    const hasDataIa = combinededDocuments.filter((doc) => {
      const activeNumberTag = showForm
        ? activeTag.numberTagShowForm
        : activeTag.numberTag
      return doc.part === activeTag.part && doc.numberTag === activeNumberTag
    })
    // console.log("activeTag", activeTag)
    // console.log("hasDataIa", hasDataIa)
    // console.log("documentLint", dataDocumentToLint)
    // console.log("combinededDocuments", combinededDocuments)

    if (hasDataIa.length > 0) {
      try {
        const results = await Promise.allSettled(
          hasDataIa.map((doc) => {
            const payload = {
              tipoPessoa: doc.part === 'Empresa' ? 'Jurídica' : 'Física',
              listaCampos: templateField,
              dadosJson: doc.dados.map((d) => `${d.key}: ${d.value}`).join(','),
              retornarGPTPrompt: false,
            }

            // console.log({ payload })

            const result = fetchWithAuth(
              `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/unifyData`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
              }
            ).then((response) => response.json())
            // console.log({ result })
            return result
          })
        )

        const combinedResult = results
          .filter(
            (result) =>
              result.status === 'fulfilled' && result.value.retorno?.dados
          )
          .reduce(
            (acc, result) => ({ ...acc, ...result.value.retorno.dados }),
            {}
          )

        Object.entries(combinedResult).forEach(([name, value]) => {
          updatedDataDocumentToLint(name, value)
        })

        // console.log('Resultados das requisições?????', results)
        // console.log('Dados combinados:', combinedResult)
      } catch (error) {
        console.error('Erro na classificação dos documentos:', error)
        toast.error('Erro na classificação dos documentos', { theme: 'light' })
      } finally {
        // console.log('Processamento finalizado')
        setIsLoadingForm(false)
      }
    }

    try {
      appInsights &&
        appInsights.trackEvent({
          name: 'Formulário preenchido com IA',
          properties: {
            documentId: idFileDoc,
          },
        })
    } catch (error) {
      appInsights && appInsights.trackException({ error: new Error(error) })
    }
  }

  useEffect(() => {
    // console.log('aqui é combined', combinededDocuments)
  }, [combinededDocuments])
  return (
    <Provider
      value={{
        startingLoading,
        errorDocBox,
        isFormDataEmpty,
        isLoadingForm,
        handleLoadingForm,
        combinededDocuments,
        handleCheckFormEmpty,
        updatedDataDocumentToLint,
        handleCollection,
        handleCopyToClipboard,
        tagRequiredFile,
        labelTag,
        showLabelTag,
        canDownloaded,
        canPrinted,
        validatedDocumentsModalOpen,
        showLabelText,
        handleOpenModalValidatedDocuments,
        handleFormDataToAi,
        showClassification,
        validationDocument,
        showContractPart,
        handleClassification,
        handleDocumentsPartArray,
        handleDataDocumentToLint,
        dataDocumentToLint,
        previewBoxVisibility,
        documentsPartArray,
        handleAddFilter,
        boxToken,
        progressBar,
        tagsWithColors,
        setTagsWithColors,
        colorsByDocumentId,
        setDocumentColor,
        getDocumentColor,
        handleActiveTag,
        activeTag,
        previewSize,
        handleConfigData,
        handleSelectedDocument,
        configData,
        configId,
        docSelected,
        handleAnalyzerData,
        documentListData,
        contractPartsDoc,
        classificationListDocs,
        filterSelectedColor,
        handleClearFilter,
        handleAllDataValidated,
        allDataValidated,
        handleFilter,
        handleErrorDocBox,
        filteredPartsContract,
        handleContractParts,
        setContractPartsSelected,
        contractPartsSelected,
        typeDocuments,
        handleTagSelected,
        partSelected,
        handleTypeDocumentsOption,
        openModalSendDocs,
        handleOpenModalSend,
        setAllDocsValidated,
        handleAllDocsValidatesValue,
        showDeleteButton,
        handleClassificationDocs,
        loadingDocumentsData,
        fileId,
        submitDocList,
        setSubmitDocList,
        updatedFilterSelected,
        collection,
        validatedDocument,
        showForm,
        setValidatedDocument,
        handleQttValidation,
        handlePartForm,
        handlePartFormUpdate,
        partFormListState,
        handleValidateDocument,
        setTotalValidateDocs,
        setIdFileDoc,
        totalValidateDocs,
        allDocsValidated,
        idFileDoc,
        analyzerDataList,
        confirmEditValuesDocLInt,
        setConfirmEditValuesDocLInt,
        setValidatedDocumentsModalOpen,
        setStartingLoading,
        showFilterButton,
        sendNewDocument,
        checkToken,
        handleUpdateActiveTag,
        openModalAllLists,
        showListDocuments,
        handleOpenModalAllLists,
        showError,
        resumeTags,
        openDocumentPreview,
        showResumeDocToContract,
        showLabelCloseButton,
        handlePreviewDocsReview,
        setAnalyzerDataList,
        setDocumentListData,
        downloadDocument,
        disableValidationButton,
      }}
    >
      {props.children}
    </Provider>
  )
}
