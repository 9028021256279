import React, { useState, useContext, useRef, useEffect, useCallback } from 'react'
import ContentPreview from 'box-ui-elements/es/elements/content-preview'
import messages from 'box-ui-elements/i18n/pt-BR'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as Delete } from '../../assets/image/delete-2.svg'
import { ReactComponent as Download } from '../../assets/image/download4.svg'
import FadeLoader from 'react-spinners/FadeLoader'
import { groupDocuments } from '../../utils/utils'

const language = 'pt-BR'
const override = {
  display: 'block',
  margin: '2rem 0',
}

const PreviewDocs = (props) => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [indexDoc, setIndexDoc] = useState()
  // const [isLoading, setIsLoading] = useState(false)
  const isLoading = false;
  //eslint-disable-next-line no-unused-vars
  // const [validatedDocument, setValidatedDocument] = useState({})
  //eslint-disable-next-line no-unused-vars
  const [keyPressed, setKeyPressed] = useState('')
  const openModalConfirmRef = useRef(null)
  // const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1200)
  const color = '#ccc'
  const {
    checkToken,
    boxToken,
    setIdFileDoc,
    idFileDoc,
    configData,
    collection,
    previewBoxVisibility,
    tagsWithColors,
    handleDocumentsPartArray,
    handleCollection,
    validationDocument,
    showDeleteButton,
    downloadDocument,
    canDownloaded,
  } = useContext(DocsContext)

  const handleOnNavigate = (e) => {
    checkToken()
    setIdFileDoc(e)
  }

  const handleOnPreview = (e) => {}

  // useEffect(() => {
  //   const documentToValidated = configData?.documents.find(
  //     (item) => item?.documentId === idFileDoc
  //   )
  //   setValidatedDocument(documentToValidated)
  // }, [idFileDoc, configData?.documents])

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsLargeScreen(window.innerWidth >= 1022)
  //   }

  //   window.addEventListener('resize', handleResize)

  //   // Cleanup listener on unmount
  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  useEffect(() => {
    // console.log({ collection })
    if (collection && collection.length > 0 && idFileDoc) {
      // console.log("collection", collection)
      const position = collection.findIndex((item) => item === idFileDoc)
      // console.log(`qual a position?`, {position, idFileDoc})
      //position ta dando -1, pq nao idfiledoc esta undefined, pulando pra funcao de delete pra verificar
      if (position !== -1) {
        // console.log(`bateu aqui?`)
        const displayPosition = position + 1
        setIndexDoc(displayPosition)
      }
    }
  }, [idFileDoc, collection])

  useEffect(() => {
    if (configData?.documents) {
      const finalDocumentsArray = groupDocuments(
        configData?.documents,
        tagsWithColors
      )
      handleDocumentsPartArray(finalDocumentsArray)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData?.documents, tagsWithColors, collection])

  // const upHandler = ({ key }) => {
  //   if (key === 'ArrowLeft') {
  //     document.querySelector('.bcpr-navigate-left')?.click()
  //     setKeyPressed(key)
  //   }
  //   if (key === 'ArrowRight') {
  //     document.querySelector('.bcpr-navigate-right')?.click()
  //     setKeyPressed(key)
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('keyup', upHandler)
  //   return () => {
  //     window.removeEventListener('keyup', upHandler)
  //   }
  // }, [upHandler])

  const upHandler = useCallback(
    ({ key }) => {
      if (key === 'ArrowLeft') {
        document.querySelector('.bcpr-navigate-left')?.click()
        setKeyPressed(key)
      }
      if (key === 'ArrowRight') {
        document.querySelector('.bcpr-navigate-right')?.click()
        setKeyPressed(key)
      }
    },
    []
  )

  useEffect(() => {
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, [upHandler])

  return (
    <>
      {openModalConfirm && <S.ModalOverlay></S.ModalOverlay>}
      {isLoading ? (
        <FadeLoader
          color={color}
          loading={isLoading}
          cssOverride={override}
          size={500}
          aria-label="Loading Spinner"
          data-testid="loader"
        /> 
      ) : !!boxToken ? (
        <S.Container
          previewBoxVisibility={previewBoxVisibility}
          // style={{ height: `calc(100vh - 124px)` }}
        // style={{
        //   height: isLargeScreen ? 'calc(100vh - 66px)' : '70vh'
        // }}
        >
          <S.BtnDownload
            validationDocument={validationDocument}
            canDownloaded={canDownloaded}
            onClick={() => downloadDocument(idFileDoc)}
            title={"Baixar o documento"}
          >
            <Download />
          </S.BtnDownload>
          <S.BtnDelete
            validationDocument={validationDocument}
            showDeleteButton={showDeleteButton}
            onClick={() => setOpenModalConfirm(true)}
            title={"Deletar o arquivo"}
          >
            <Delete />
            <S.ContentModal>
              {openModalConfirm && (
                <S.ModalContainer ref={openModalConfirmRef}>
                  <S.ContentBox></S.ContentBox>
                  <S.ModalContent onClick={(e) => e.stopPropagation()}>
                    <strong>Confirmação de Exclusão</strong>
                    <p>Tem certeza de que deseja excluir este documento?</p>
                    <p>Essa ação é irreversível.</p>
                    <div>
                      <button
                        onClick={() => {
                          handleCollection()
                          setOpenModalConfirm(false)
                        }}
                        style={{ backgroundColor: '#9ca624' }}
                      >
                        CONFIRMAR
                      </button>
                      <button
                        onClick={() => setOpenModalConfirm(false)}
                        style={{ backgroundColor: '#a6151c' }}
                      >
                        CANCELAR
                      </button>
                    </div>
                  </S.ModalContent>
                </S.ModalContainer>
              )}
            </S.ContentModal>
          </S.BtnDelete>
          <S.NumberDocs>
            {indexDoc}/{collection?.length}
          </S.NumberDocs>
          <ContentPreview
            hasHeader={true}
            canDownload={false}
            canPrint={false}
            fileId={idFileDoc}
            collection={collection}
            token={boxToken}
            language={language}
            messages={messages}
            showAnnotations={false}
            onNavigate={handleOnNavigate}
            onPreview={handleOnPreview}
          />
        </S.Container>
      ) : (
        ''
      )}
    </>
  )
}

export default PreviewDocs
