// import msalInstance from '../msal/msalInstance'

// const getToken = async () => {
//   const account = msalInstance.getAllAccounts()[0]

//   if (!account || !localStorage.getItem('token')) {
//     throw new Error('No active account! Verify a user has been signed in.')
//   }

//   const timestamp = Math.floor(new Date().getTime() / 1000)
//   let tokenDefault = localStorage.getItem('token')

//   if (
//     !localStorage.getItem('tokenExp') ||
//     !localStorage.getItem('token') ||
//     (localStorage.getItem('tokenExp') &&
//       parseInt(localStorage.getItem('tokenExp')) <= timestamp)
//   ) {
//     try {
//       const response = await msalInstance.acquireTokenSilent({
//         account,
//         forceRefresh: true,
//       })
//       tokenDefault = response?.idToken
//       localStorage.setItem('token', response?.idToken)
//       localStorage.setItem(
//         'tokenExp',
//         response?.account?.idTokenClaims?.exp.toString()
//       )
//     } catch (error) {
//       await msalInstance.loginRedirect()
//     }
//   }
//   return tokenDefault
// }

// export default getToken
import msalInstance from '../msal/msalInstance'
 
const getToken = async () => {
  const account = msalInstance.getAllAccounts()[0]

  if (!account || !localStorage.getItem('token')) {
    throw new Error('No active account! Verify a user has been signed in.')
  }

  const timestamp = Math.floor(new Date().getTime() / 1000)
  let tokenDefault = localStorage.getItem('token')

  if (
    !localStorage.getItem('tokenExp') ||
    !localStorage.getItem('token') ||
    (localStorage.getItem('tokenExp') &&
      parseInt(localStorage.getItem('tokenExp')) <= timestamp)
  ) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        account,
        forceRefresh: true,
      })
      tokenDefault = response?.idToken
      localStorage.setItem('token', response?.idToken)
      localStorage.setItem(
        'tokenExp',
        response?.account?.idTokenClaims?.exp.toString()
      )
    } catch (error) {
      await msalInstance.loginRedirect()
    }
  }
  return tokenDefault
}
 
export default getToken