import styled from 'styled-components'

export const Header = styled.header`
  height: 4rem;
  box-shadow: 0px 4px 5.7px -3px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  position: ${({ openDocumentPreview }) =>
    openDocumentPreview ? 'fixed' : 'relative'};
  z-index: 4;
  width: 100%;
`

export const Content = styled.div`
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 600px) {
    /* background-color: red; */
    width: 95%;
  }
  @media (min-width: 800px) {
    /* background-color: red; */
    width: 94%;
  }
  @media (min-width: 1000px) {
    /* background-color: red; */
    width: 96%;
  }
  @media (min-width: 1200px) {
    /* background-color: red; */
    width: 96%;
  }
  @media (min-width: 1400px) {
    /* background-color: red; */
    width: 96.5%;
  }
  @media (min-width: 1500px) {
    /* background-color: red; */
    width: 96.5%;
  }
`

export const BtnBack = styled.button`
  background-color: #a6151c;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  width: 2.5rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`

export const Logo = styled.span`
  display: flex;
  align-items: center;
`

export const BtnClose = styled.button`
  /* background-color: #a6151c; */
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  width: 2.5rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  svg path {
    /* fill: #a6151c; */
    stroke: #878787;
  }
  &:hover {
    background-color: #eee;
  }
  &:active {
    transform: scale(0.98);
  }
`

export const BtnOff = styled.button`
  background-color: transparent;
  border: none;
  img {
    width: 1.2rem;
  }
`
