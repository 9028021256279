import React, { useContext, useEffect, useState, useRef } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as ArrowD } from '../../assets/image/arrow-down.svg'
import { ReactComponent as Cancel } from '../../assets/image/close-review.svg'
import { ReactComponent as Edit } from '../../assets/image/edit14.svg'
// import { ReactComponent as ComboBox } from '../../assets/image/dropdown6.svg'
import { ReactComponent as Confirm } from '../../assets/image/ok-review.svg'
import { ReactComponent as Confirm2 } from '../../assets/image/confirm-9.svg'
import CopyValue from '../../assets/image/copy-ok.svg'
import insertSpaceBetweenWords, { groupDocuments } from '../../utils/utils'
// import Ok from '../../assets/image/check-mark-png.png'
import CopyDataImage from '../../assets/image/copy2.svg'
// import { contractOptions } from '../../constant/documentstype'
import ModalDocumentsLint from '../../components/modal-documents-lint/ModalDocumentsLint'
// import SelectedDocumentLint from '../../components/selected-document-lint/SelectedDocumentLint'

const DocumentReview = () => {
  const [checkedItems, setCheckedItems] = useState({})
  // const [docToLint, setDocToLint] = useState([])
  const [keyCheck, setkeyCheck] = useState()
  // const [currentCheckBox, setcurrentCheckBox] = useState({})
  const [changeCheckKey, setChangeCheckKey] = useState({})
  const [combinededDocuments, setCombinededDocuments] = useState([])
  // const [isDocListVisible, setDocListVisible] = useState(false)
  const [openLintList, setOpenLintList] = useState([])
  const timeoutRef = useRef(null)
  const optionLintContainer = useRef(null)
  // const [copiedText, setCopiedText] = useState('')
  // const [openCopyButton, setOpenCopyButton] = useState(false)
  const [editingItems, setEditingItems] = useState({})
  const [copiedItems, setCopiedItems] = useState({})
  const [editedValues, setEditedValues] = useState({})
  const [openSections, setOpenSections] = useState({})
  const [openModalSubmit, setOpenModalSubmit] = useState(false)
  // const [hasResumeTag, setHasResumeTag] = useState(false)

  const {
    setSubmitDocList,
    handleDocumentsPartArray,
    configData,
    // confirmEditValuesDocLInt,
    setConfirmEditValuesDocLInt,
    documentsPartArray,
    // handlePreviewDocumentsPage,
    analyzerDataList,
    labelTag,
    submitDocList,
    tagsWithColors,
    // resumeTags,
    // handlePreviewDocsReview,
  } = useContext(DocsContext)

  useEffect(() => {
    if (configData?.documents) {
      const finalDocumentsArray = groupDocuments(
        configData?.documents,
        tagsWithColors
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
      handleDocumentsPartArray(finalDocumentsArray)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData?.documents, tagsWithColors])

  const groupedDocuments = documentsPartArray.reduce((acc, doc) => {
    const key = `${doc.part}-${doc.numberTag}`

    if (!acc[key]) {
      acc[key] = {
        id: Object.keys(acc)?.length + 1,
        part: doc.part,
        numberTag: doc.numberTag,
        documents: [],
        tagAdd: doc.tagAdd,
      }
    }

    doc.documentId?.forEach((id) => {
      const docConfigData = configData.documents?.find(
        (item) => item.documentId === id
      )
      const docAnalyzerData = analyzerDataList?.find(
        (item) => item?.retorno && item.retorno.idDocBox === id
      )

      if (docConfigData || docAnalyzerData) {
        acc[key].documents.push({
          documentId: id,
          documentData: {
            configData: docConfigData || {},
            analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
          },
        })
      }
    })

    return acc
  }, {})

  const otherDocuments = configData?.documents?.reduce((acc, doc) => {
    if (!doc.part && !doc.numberTag) {
      const docAnalyzerData = analyzerDataList?.find(
        (item) => item?.retorno && item.retorno.idDocBox === doc.documentId
      )

      acc.push({
        documentId: doc.documentId,
        documentData: {
          configData: doc || {},
          analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
        },
      })
    }
    return acc
  }, [])

  if (otherDocuments?.length > 0) {
    groupedDocuments['Outros'] = {
      id: Object.keys(groupedDocuments)?.length + 1,
      part: 'Outros',
      numberTag: null,
      documents: otherDocuments,
    }
  }

  const finalArray = Object.values(groupedDocuments)

  useEffect(() => {
    const newCombinededDocuments = finalArray.map((doc) => {
      const dadosList =
        doc.documents?.flatMap((item) => {
          const dados = item?.documentData?.analyzerData?.dados || {}
          const label = item?.documentData?.configData?.classification?.label

          return Object.entries(dados).map(([key, value]) => ({
            key,
            label: label ? label : 'Não classificado',
            value: value ? value : 'Não cadastrado',
          }))
        }) || []

      const dadosSeparados = dadosList
        .filter(({ key }) => !/hidden/i.test(key))
        .map(({ key, value, label }) => ({
          key,
          label,
          value,
        }))

      return {
        part: doc?.part,
        numberTag: doc?.numberTag,
        dados: dadosSeparados,
      }
    })

    setCombinededDocuments(newCombinededDocuments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckBoxClick = (part, numberTag, index) => {
    const key = `${part}-${numberTag}-${index}`
    setkeyCheck(!keyCheck)
    // const isChecked = keyCheck
    setChangeCheckKey(key)
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [key]: !prevCheckedItems[key],
    }))
  }

  useEffect(() => {
    if (!checkedItems[changeCheckKey]) {
      const updatedSubmitDocList = submitDocList.map((doc) => ({
        ...doc,
        item: doc.item.filter((item) => item.id !== changeCheckKey),
      }))
      setSubmitDocList(updatedSubmitDocList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyCheck])

  // setChangeCheckKey({ keyCheck, isChecked })
  const editValueDocument = (part, numberTag, index) => {
    const key = `${part}-${numberTag}-${index}`

    setEditingItems((prevEditingItems) => ({
      ...prevEditingItems,
      [key]: !prevEditingItems[key],
    }))
  }

  const copyToClipboard = (item, part, numberTag, index) => {
    // console.log('text', { item, part, numberTag, index })
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // navigator.clipboard
    //   .writeText(text)
    //   .then(() => {
    //     setCopiedText(text)
    //     setOpenCopyButton(true)

    //     timeoutRef.current = setTimeout(() => {
    //       setOpenCopyButton(false)
    //       setCopiedText(null)
    //     }, 950)
    //   })
    //   .catch((err) => {
    //     //console.error('Falha ao copiar texto: ', err)
    //   })
    const text = item?.value

    navigator.clipboard
      .writeText(text)
      .then(() => {
        const key = `${part}-${numberTag}-${index}`
        setCopiedItems((prev) => ({
          ...prev,
          [key]: true,
        }))

        timeoutRef.current = setTimeout(() => {
          setCopiedItems((prev) => ({
            ...prev,
            [key]: false,
          }))
        }, 900)
      })
      .catch((err) => {
        console.error(`Falha ao copiar texto`, err)
      })
  }

  const handleVisibilyDataDoc = (part, numberTag) => {
    const key = `${part}-${numberTag}`
    setOpenSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  const handleClickOutside = (event) => {
    if (
      (optionLintContainer?.current &&
        optionLintContainer?.current.contains(event.target)) ||
      openLintList?.includes(event.target?.id)
    ) {
      return
    } else {
      setOpenLintList([])
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLintList])

  const handleUpdatedCombinedDocument = (part, numberTag, index, value) => {
    const newValue = editedValues[`${part}-${numberTag}-${index}`] || value
    const findEditConfirm = `${part}-${numberTag}-${index}`
    setConfirmEditValuesDocLInt((prev) => {
      return [...prev, findEditConfirm]
    })
    const updateData = combinededDocuments.map((document) => {
      if (
        document.part === part &&
        document.numberTag === numberTag
      ) {
        return {
          ...document,
          dados: document.dados.map((data, dataIndex) => {
            if (dataIndex === index) {
              return { ...data, value: newValue }
            }
            return data
          }),
        }
      }
      return document
    })

    setCombinededDocuments(updateData)
  }

  const handleEditToggle = (part, numberTag, index) => {
    const key = `${part}-${numberTag}-${index}`
    setEditingItems((prevEditingItems) => ({
      ...prevEditingItems,
      [key]: !prevEditingItems[key],
    }))
  }

  const handleOpenModalSubmit = () => {
    setOpenModalSubmit(!openModalSubmit)
  }

  useEffect(() => {}, [])

  return (
    <S.Section>
      {openModalSubmit && (
        <ModalDocumentsLint
          docToLint={submitDocList}
          handleOpenModalSubmit={handleOpenModalSubmit}
        />
      )}
      <S.Content></S.Content>
      <S.ContentBox></S.ContentBox>
      <S.Container>
        <S.DataTitle>
          <div>
            <h2>LISTA DE DOCUMENTAÇÃO</h2>
            <hr />
          </div>
          {/* <button onClick={handleOpenModalSubmit}>SUBMETER</button> */}
        </S.DataTitle>
        {combinededDocuments
          ?.filter(
            (docTag) =>
              docTag.part !== 'Pendentes' &&
              docTag.part !== 'Validados' &&
              docTag.part !== 'Outros'
          )
          .map((doc, index) => {
            return (
              doc.dados.length > 0 && (
                <S.ContentList key={index}>
                  <S.TagName
                    onClick={() =>
                      handleVisibilyDataDoc(doc.part, doc.numberTag)
                    }
                  >
                    <ArrowD
                      // style={{
                      //   transform: isDocListVisible[
                      //     `${doc.part}-${doc.numberTag}`
                      //   ]
                      //     ? 'rotate(180deg)'
                      //     : 'rotate(0deg)',
                      //   transition: 'transform 0.2s ease',
                      // }}
                    />
                    <h3>
                      {doc.part}
                      {doc.numberTag && doc.part !== labelTag.part
                        ? ` - ${doc.numberTag}`
                        : ''}
                    </h3>
                  </S.TagName>
                  {openSections[`${doc.part}-${doc.numberTag}`] && (
                    <S.DocumentsList key={index}>
                      {Array.isArray(doc?.dados) &&
                        doc?.dados
                          .sort((key1, key2) =>
                            key1?.key.localeCompare(key2?.key)
                          )
                          .map((item, index) => (
                            <S.DocumentsListItem
                              key={index}
                              ref={optionLintContainer}
                            >
                              <S.InfoData>
                                <S.CheckBox
                                  style={{
                                    background: checkedItems[
                                      `${doc.part}-${doc.numberTag}-${index}`
                                    ]
                                      ? '#a6151c'
                                      : 'transparent',
                                    border: checkedItems[
                                      `${doc.part}-${doc.numberTag}-${index}`
                                    ]
                                      ? 'none'
                                      : '1px solid', // Adicionando borda para melhor visibilidade
                                  }}
                                  title={`Selecionar`}
                                  onClick={() =>
                                    handleCheckBoxClick(
                                      doc.part,
                                      doc.numberTag,
                                      index
                                    )
                                  }
                                >
                                  {checkedItems[
                                    `${doc.part}-${doc.numberTag}-${index}`
                                  ] ? (
                                    // <img src={Ok} alt="ok" />
                                    <Confirm2 />
                                  ) : null}
                                </S.CheckBox>
                                <S.ListData>
                                  <strong>
                                    {insertSpaceBetweenWords(item.key)}:
                                  </strong>{' '}
                                  ({item.label}) -
                                  {editingItems[
                                    `${doc.part}-${doc.numberTag}-${index}`
                                  ] ? (
                                    <S.ChangeData>
                                      <S.InputEdit
                                        type="text"
                                        value={
                                          editedValues[
                                            `${doc.part}-${doc.numberTag}-${index}`
                                          ] !== undefined
                                            ? editedValues[
                                                `${doc.part}-${doc.numberTag}-${index}`
                                              ]
                                            : item.value
                                        }
                                        onChange={(e) =>
                                          setEditedValues((prevValues) => ({
                                            ...prevValues,
                                            [`${doc.part}-${doc.numberTag}-${index}`]:
                                              e.target.value,
                                          }))
                                        }
                                      />
                                      <S.BtnCancel
                                        title={`Cancelar`}
                                        onClick={() => {
                                          handleEditToggle(
                                            doc.part,
                                            doc.numberTag,
                                            index
                                          )
                                        }}
                                      >
                                        <Cancel />
                                      </S.BtnCancel>
                                      <S.BtnConfirm
                                        title={`Confirmar`}
                                        onClick={() => {
                                          handleEditToggle(
                                            doc.part,
                                            doc.numberTag,
                                            index
                                          )
                                          handleUpdatedCombinedDocument(
                                            doc.part,
                                            doc.numberTag,
                                            index,
                                            item.value
                                          )
                                        }}
                                      >
                                        <Confirm />
                                      </S.BtnConfirm>
                                    </S.ChangeData>
                                  ) : (
                                    <p>{item.value}</p>
                                  )}
                                  {!editingItems[
                                    `${doc.part}-${doc.numberTag}-${index}`
                                  ] &&
                                  checkedItems[
                                    `${doc.part}-${doc.numberTag}-${index}`
                                  ] ? (
                                    <S.BtnEdit
                                      title={`Editar`}
                                      onClick={() =>
                                        editValueDocument(
                                          doc.part,
                                          doc.numberTag,
                                          index
                                        )
                                      }
                                    >
                                      <Edit />
                                    </S.BtnEdit>
                                  ) : null}
                                  {/* {!editingItems[
                                      `${doc.part}-${doc.numberTag}-${index}`
                                    ] &&
                                    checkedItems[
                                      `${doc.part}-${doc.numberTag}-${index}`
                                    ] ? (
                                      <S.ComboBoxWrapper
                                        ref={optionLintContainer}
                                      >
                                        <S.ComboBoxToggle
                                          title={`Abrir opções`}
                                          id={`${doc.part}-${doc.numberTag}-${index}`}
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            handleOpenLintList(
                                              event,
                                              doc.part,
                                              doc.numberTag,
                                              index
                                            )
                                          }}
                                        >
                                          <ComboBox />
                                        </S.ComboBoxToggle>
                                        {openLintList.includes(
                                          `${doc.part}-${doc.numberTag}-${index}`
                                        ) && (
                                          <S.ComboBoxOptions
                                            ref={optionLintContainer}
                                          >
                                            {contractOptions.map(
                                              (option, indexOption) => (
                                                <S.ComboBoxOptionsItem
                                                  key={indexOption}
                                                  onClick={() =>
                                                    handleOptionToLint(
                                                      doc.part,
                                                      doc.numberTag,
                                                      index,
                                                      option.key,
                                                      option?.label,
                                                      item.value
                                                    )
                                                  }
                                                >
                                                  <button></button>
                                                  {option.label}
                                                </S.ComboBoxOptionsItem>
                                              )
                                            )}
                                          </S.ComboBoxOptions>
                                        )}
                                      </S.ComboBoxWrapper>
                                    ) : null} */}
                                </S.ListData>
                              </S.InfoData>
                              <S.CopyData>
                                <S.BtnCopy
                                  title={'Copiar'}
                                  onClick={() =>
                                    copyToClipboard(
                                      item,
                                      doc.part,
                                      doc.numberTag,
                                      index
                                    )
                                  }
                                >
                                  <img src={CopyDataImage} alt="icon" />
                                </S.BtnCopy>
                                <S.ConfirmCopyValue
                                  openCopyButton={
                                    copiedItems[
                                      `${doc.part}-${doc.numberTag}-${index}`
                                    ]
                                  }
                                  visible={
                                    copiedItems[
                                      `${doc.part}-${doc.numberTag}-${index}`
                                    ]
                                  }
                                >
                                  <img src={CopyValue} alt="valor copiado" />
                                  Copiado
                                </S.ConfirmCopyValue>
                              </S.CopyData>
                              {/* {
                                // !editingItems[
                                //   `${doc.part}-${doc.numberTag}-${index}`
                                // ] &&
                                checkedItems[
                                  `${doc.part}-${doc.numberTag}-${index}`
                                ] && matchingTags.length > 0 ? (
                                  <S.MarkDataWrapper>
                                    <SelectedDocumentLint
                                      part={doc?.part}
                                      options={matchingTags}
                                      value={item?.value}
                                      id={`${doc.part}-${doc.numberTag}-${index}`}
                                      itemKey={`${doc?.part}-${doc?.numberTag}`}
                                    />
                                  </S.MarkDataWrapper>
                                ) : null
                              } */}
                            </S.DocumentsListItem>
                          ))}
                    </S.DocumentsList>
                  )}
                </S.ContentList>
              )
            )
          })}
      </S.Container>
    </S.Section>
  )
}

export default DocumentReview
