import styled, { css } from 'styled-components'

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  font-size: 0.9rem;
`

export const HasFilter = styled.button`
  position: relative;
  display: flex;
  margin: 1rem 0 0;
  justify-content: center;
  align-items: center;
  height: ${({ validationDocument }) =>
    validationDocument ? '2.2rem' : '2.2rem'};
  border: ${({ filterSelectedColor, validatedfilterdocument }) =>
    filterSelectedColor
      ? validatedfilterdocument
        ? '1px solid #9ca624'
        : '1px solid #a6151c'
      : '1px solid rgb(204, 204, 204)'};
  border-radius: 8px;
  background-color: ${({ filterSelectedColor, validatedfilterdocument }) =>
    filterSelectedColor
      ? validatedfilterdocument
        ? '#9ca624'
        : '#a6151c'
      : 'transparent'};
  color: ${({ filterSelectedColor }) =>
    filterSelectedColor ? '#ffffff' : '#373737'};
  cursor: ${({ validatedDocument }) =>
    validatedDocument ? 'not-allowed' : 'pointer'};

  padding: ${({filterSelectedColor}) => filterSelectedColor ? '0 0.6rem' :  '0rem'};
  height: 2.3rem;
  min-width: 2.4rem;
  h4 {
    font-size: 0.9rem;
    font-family: 'Museo500sans';
    margin: 0 0.25rem 0 0.5rem;
  }
  svg {
    width: 1.2rem;
    height: 1.2rem;
    /* color: ${({ filterSelectedColor }) =>
      filterSelectedColor ? '#ffffff' : '#878787'}; */
    color: ${({ validatedfilterdocument }) =>
      validatedfilterdocument ? '#9ca624' : '#a6151c'};
  }
  svg path {
    fill: ${({ filterSelectedColor }) =>
      filterSelectedColor ? '#ffffff' : '#878787'};
    /* fill: ${({ validatedfilterdocument }) =>
      validatedfilterdocument ? '#9ca624' : '#a6151c'}; */
    /* fill: ${({ filterSelectedColor, validatedfilterdocument }) => {
      if (filterSelectedColor) return '#fff'
      return validatedfilterdocument ? '#9ca624' : '#a6151c'
    }}; */
  }
  strong {
    line-height: 4px;
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 100px;
    color: ${({ validatedfilterdocument }) =>
      validatedfilterdocument ? '#9ca624' : '#a6151c'};
  }
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
  }
  &:active {
    transform: scale(0.98);
  }
`

export const ClassificationDoctype = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  h2 {
    color: #373737;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: 'Museo500sans';
    letter-spacing: 0cap.5;
    color: #373737;
    margin: 0 0.5rem 0.5rem 0;
  }
`

export const FilterData = styled.div`
  display: ${({ openDataFilter }) => (openDataFilter ? 'flex' : 'none')};
  align-items: center;
`

export const FilterList = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
  min-width: 140px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  padding: 0.2rem;
  background-color: #fff;
  border-radius: 8px;
  display: ${({ openFilterList }) => (openFilterList ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
`

export const FilterNotification = styled.div`
  background-color: ${(props) => props.color};
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 100px;
  bottom: 82%;
  left: 92%;
  position: absolute;
`

export const FilterListItem = styled.li`
  width: 100%;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  border-bottom: 0.5px solid rgb(221, 221, 221);
  font-family: 'Museo500sans';
  color: ${(props) => (props.isSelected ? '#fff' : '#878787')};
  border-radius: ${(props) => (props.isSelected ? '8px' : '0px')};
  background-color: ${(props) => props.color};
  &::first-letter {
    text-transform: uppercase;
  }
  &:last-child {
    border: none;
  }
  &:hover {
    background-color: ${(props) => props.filterSelectedColor};
    color: #fff;
    border-radius: 8px;
  }
`

export const PartsAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const PartList = styled.ul`
  /* display: ${({ openList }) => (openList ? 'flex' : 'none')}; */
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Museo500sans';
`

export const PartListItem = styled.li`
  /* background: rgb(228 228 228 / 67%); */
  /* background-color: ${(props) => props.selectedColor}; */
  padding: 0.5rem 0.8rem;
  border-radius: 8px;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin: 0px 0.5rem 0.5rem 0px;
  cursor: pointer;

  /* 
  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${props.selectedColor};
    `}
  ${(props) =>
    !props.isSelected &&
    css`
      background-color: rgb(228 228 228 / 67%);
    `} */

  &:active {
    transform: scale(0.98);
  }
`

export const ClearFilterButton = styled.li`
  width: 2.2rem;
  height: 2.1rem;
  display: ${({ filterSelectedColor }) =>
    filterSelectedColor ? 'none' : 'flex'};
  /* padding: 0.5rem 0.8rem; */
  /* margin: 0 0 .05rem 0; */
  color: red;
  /* background: rgba(228, 228, 228, 0.67); */
  background: #a6151c;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0.5rem 0.5rem 0px;
  cursor: pointer;
  img {
    width: 1.3rem;
  }

  &:active {
    transform: scale(0.98);
  }
`

export const AddTagParts = styled.button`
  display: ${({ openList }) => (openList ? 'none' : 'flex')};
  background: ${({ validatedDocument }) =>
    validatedDocument ? '#B0B3B7' : '#a6151c'};
  color: rgb(255, 255, 255);
  border: none;
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  cursor: ${({ validatedDocument }) =>
    validatedDocument ? 'not-allowed' : 'pointer'};

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    box-shadow: ${({ validatedDocument }) =>
      validatedDocument ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
  }
`

export const NavDocumentTypeList = styled.nav`
  position: relative;
  flex: 1;
  display: flex;
`

export const ButtonConfirmDoc = styled.button`
  flex: 1;
  background: none;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  height: 6.5vh;
  justify-content: space-between;
  padding: 0 1rem;
  margin: 0 0 0 0.5rem;
  align-items: center;
  color: ${({ validatedDocument }) =>
    validatedDocument ? '#B0B3B7' : '#a6151c'};
  font-size: 0.9rem;
  font-family: 'Museo700sans', sans-serif;
  cursor: ${({ validatedDocument }) =>
    validatedDocument ? 'not-allowed' : 'pointer'};
  transition: transform 0.4s ease;
  img {
    width: 1.1rem;
  }

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    box-shadow: ${({ validatedDocument }) =>
      validatedDocument ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
  }

  @media (min-height: 1500px) {
    height: 4.5vh;
  }
`

/*socio locatario - fiador 1*/
export const DocumentList = styled.ul`
  display: flex;
  flex-direction: column;
  height: ${({ getListDocTypes }) => (getListDocTypes ? '226px' : '0')};
  transition: height 0.3s ease-in;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  left: 7px;
  width: 98%;
  z-index: 99;
  background: #fff;
  border: ${({ getListDocTypes }) =>
    getListDocTypes ? '1px solid #eee' : 'none'};
  border-radius: 8px;
  padding: ${({ getListDocTypes }) =>
    getListDocTypes ? '0.2rem 1rem 0.4rem' : '0 1rem'};
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 8px;
    background: linear-gradient(
      0deg,
      rgba(244, 244, 244, 0.5) 0%,
      rgba(255, 250, 250, 0.5) 100%
    );
  }

  &::-webkit-scrollbar-thumb {
    background: #a6151c;
    border-radius: 8px;
  }
`

export const DocumentListItem = styled.li`
  opacity: ${({ getListDocTypes }) =>
    getListDocTypes ? '1' : '0'}; /* Começa com opacidade 0 */
  padding: 0.8rem;
  width: 100%;
  color: #8a8f95;
  font-family: 'Museo700sans';
  border-bottom: 1px solid #eee;
  transition: opacity 0.5s ease;
  cursor: pointer;
  position: relative;
  &:nth-child(n) {
    transition-delay: ${({ getListDocTypes }) =>
      getListDocTypes ? '0s' : '0.1s'};
  }

  transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); // easeOutQuart
  -webkit-mask-image: -webkit-radial-gradient(red, black); // force */

  &:hover {
    color: #fff;
    border-radius: 8px;
  }

  &:last-child {
    border-bottom: none;
  }
`

export const FillContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #a6151c;
  border-radius: 8px;
  transition: transform 0.8s; // Altera a transição para aplicar apenas ao transform */
  transform: translateX(-100%);
  z-index: -1; // Adiciona um índice Z para o pseudo-elemento estar atrás dos itens da lista

  ${DocumentListItem}:hover & {
    transform: translateX(0);
  }
`
