import React, { useContext, useEffect, useState, useRef, useMemo } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as ArrowD } from '../../assets/image/arrow-down.svg'
import { ReactComponent as Cancel } from '../../assets/image/close-review.svg'
import { ReactComponent as Edit } from '../../assets/image/edit14.svg'
import { ReactComponent as ComboBox } from '../../assets/image/dropdown6.svg'
import { ReactComponent as Confirm } from '../../assets/image/ok-review.svg'
import { ReactComponent as Confirm2 } from '../../assets/image/confirm-9.svg'
import CopyValue from '../../assets/image/copy-ok.svg'
import insertSpaceBetweenWords, { groupDocuments } from '../../utils/utils'
import Ok from '../../assets/image/check-mark-png.png'
import CopyDataImage from '../../assets/image/copy2.svg'
import {
  contractOptions,
  loadingDocSummary,
} from '../../constant/documentstype'
import ModalDocumentsLint from '../../components/modal-documents-lint/ModalDocumentsLint'
import SelectedDocumentLint from '../../components/selected-document-lint/SelectedDocumentLint'

const DocumentSummary = () => {
  const [checkedItems, setCheckedItems] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [keyCheck, setkeyCheck] = useState()
  const [currentCheckBox, setcurrentCheckBox] = useState({})
  const [changeCheckKey, setChangeCheckKey] = useState({})
  // const [combinededDocuments, setCombinededDocuments] = useState([])
  const [isDocListVisible, setDocListVisible] = useState(false)
  const [openLintList, setOpenLintList] = useState([])
  const timeoutRef = useRef(null)
  const optionLintContainer = useRef(null)
  const [copiedText, setCopiedText] = useState('')
  const [openCopyButton, setOpenCopyButton] = useState(false)
  const [editingItems, setEditingItems] = useState({})
  const [copiedItems, setCopiedItems] = useState({})
  const [editedValues, setEditedValues] = useState({})
  const [openSections, setOpenSections] = useState({})
  const [openModalSubmit, setOpenModalSubmit] = useState(false)
  const [hasResumeTag, setHasResumeTag] = useState(false)

  const {
    setSubmitDocList,
    activeTag,
    handleDocumentsPartArray,
    configData,
    isLoadingForm,
    handleLoadingForm,
    combinededDocuments,
    setConfirmEditValuesDocLInt,
    documentsPartArray,
    analyzerDataList,
    submitDocList,
    tagsWithColors,
    resumeTags,
  } = useContext(DocsContext)


  useEffect(() => {
    if (!checkedItems[changeCheckKey]) {
      const updatedSubmitDocList = submitDocList.map((doc) => ({
        ...doc,
        item: doc.item.filter((item) => item.id !== changeCheckKey),
      }))
      setSubmitDocList(updatedSubmitDocList)
    }
  }, [keyCheck])

  const copyToClipboard = (item, part, numberTag, index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    const text = item?.value

    navigator.clipboard
      .writeText(text)
      .then(() => {
        const key = `${part}-${numberTag}-${index}`

        setCopiedItems((prev) => ({
          ...prev,
          [key]: true,
        }))

        timeoutRef.current = setTimeout(() => {
          setCopiedItems((prev) => ({
            ...prev,
            [key]: false,
          }))
        }, 900)
      })
      .catch((err) => {
        console.error(`Falha ao copiar texto`, err)
      })
  }

  useEffect(() => {
    // console.log('combinededDocuments', combinededDocuments)
  }, [combinededDocuments])

  return isLoadingForm ? (
    <S.LoadingList>
      {loadingDocSummary.map((item, idx) => (
        <S.LoadingListItem key={idx}>
          {item}
          <p></p>
          <h6></h6>
        </S.LoadingListItem>
      ))}
    </S.LoadingList>
  ) : (
    // <>oi</>
    <S.Section>
      <S.Container>
        {combinededDocuments &&
          combinededDocuments
            ?.filter(
              (docTag) =>
                docTag.part == activeTag.part &&
                docTag.numberTag == activeTag.numberTagShowForm
            )
            .map((doc, index) => {
              // const matchingTags = resumeTags.filter(
              //   (item) => item.tag === doc.part
              // )
              // console.log('aqui dentre', { combinededDocuments })
              return (
                <S.ContentList key={index}>
                  <S.DocumentsList key={index}>
                    {doc?.dados
                      .sort((key1, key2) => key1?.key.localeCompare(key2?.key))
                      .map((item, index) => (
                        <S.DocumentsListItem
                          key={index}
                          ref={optionLintContainer}
                        >
                          <S.InfoData>
                            <S.ListData>
                              <strong>
                                {insertSpaceBetweenWords(item.key)}:
                              </strong>
                              ({item.label}) - <p>{item.value}</p>
                            </S.ListData>
                          </S.InfoData>
                          <S.CopyData>
                            <S.BtnCopy
                              title={'Copiar'}
                              onClick={() =>
                                copyToClipboard(
                                  item,
                                  doc.part,
                                  doc.numberTag,
                                  index
                                )
                              }
                            >
                              <img src={CopyDataImage} alt="icon" />
                            </S.BtnCopy>
                            <S.ConfirmCopyValue
                              openCopyButton={
                                copiedItems[
                                  `${doc.part}-${doc.numberTag}-${index}`
                                ]
                              }
                              visible={
                                copiedItems[
                                  `${doc.part}-${doc.numberTag}-${index}`
                                ]
                              }
                            >
                              <img src={CopyValue} alt="valor copiado" />
                              Copiado
                            </S.ConfirmCopyValue>
                          </S.CopyData>
                        </S.DocumentsListItem>
                      ))}
                  </S.DocumentsList>
                </S.ContentList>
              )
            })}
      </S.Container>
    </S.Section>
  )
}

export default DocumentSummary
